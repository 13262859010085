import * as React from 'react'
import { UserDto } from '../../dtos/user'
import { validateSession } from '../../_utils/utils'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../stores/app'
import { sendGAEvent } from '../../components/ga-helper/ga-helper'
import { AppService } from '../../services/index'
import { Dispatch } from 'react'
import { AUTH0_PLATFORM_REDIRECT_PATHNAME, AUTH0_STATE_SESSION_KEY } from '../../constants'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { PlatformIdentityContext } from '../platform-identity-provider/platform-identity-provider'
import { buildAuthorizeRoute } from '../../_utils/routing'
import axios from 'axios'
import aqe from '@pushly/aqe'
import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringParams } from '../../_utils/query-string'
import { Auth0InvalidAuthorizationParamsException } from '../../exceptions/auth0-invalid-auth-params.exception'
import { SessionValidationFailedException } from '../../exceptions/session-validation-failed.exception'
import { ErrorPage } from '../../components/error-page/error-page'
import { synchronizeDomainStates } from '../../_utils/domain'

export type PlatformUser =
    | {
          authenticated: false
          revalidate?: boolean
      }
    | {
          authenticated: true
          user: UserDto
          revalidate?: boolean
      }

type UserContext = PlatformUser & { assignUser?: Dispatch<PlatformUser> }

const initialPlatformUser: PlatformUser = { authenticated: false }
export const PlatformUserContext = React.createContext<UserContext>(initialPlatformUser)

export const UserProvider: React.FC<any> = ({ children, ...props }) => {
    const loc = useLocation()
    const idpContext = React.useContext(PlatformIdentityContext)

    const appState = useService(AppState)
    const appService = useService(AppService)

    const [userContext, setUserContext] = React.useState<PlatformUser>({ ...initialPlatformUser })
    const [initialUserContextLoaded, setInitialUserContextLoaded] = React.useState<boolean>(false)
    const [loadError, setLoadError] = React.useState<Error | null>(null)
    const [appStateInitialized, setAppStateInitialized] = React.useState<boolean>(false)
    const inAuth0Flow = React.useRef(false)

    const handleLoadError = React.useCallback((error: Error) => {
        setLoadError(error)

        appState.isAuthenticated = false
        appService.unsetAppLoading()

        setInitialUserContextLoaded(true)
    }, [])

    const authorizeAndLoadUserViaAuth0Code = React.useCallback(async (code: string) => {
        if (!idpContext.loaded) {
            // FIXME: extract error handling to HOC at highest level and capture
            throw new Error('IDP not available')
        }

        window.sessionStorage.removeItem(AUTH0_STATE_SESSION_KEY)

        const label = idpContext.whiteLabelSettings
        const query = [
            `code=${code}`,
            `redirect_uri=${buildAuthorizeRoute(idpContext.whiteLabelSettings.getHostname())}`,
            `tenant_id=${label.getAuth0TenantId()}`,
            `client_id=${label.getAuth0PlatformClientId()}`,
        ].join('&')

        try {
            const res = await axios.get<any>(`${aqe.defaults.publicApiDomain}/auth/token?${query}`, {
                bypassGlobalErrorHandlers: true,
            } as any)

            if (res.status === 200) {
                await setupInitialUserContext(UserDto.fromApiResponse(res.data.data))

                const redirect = localStorage.getItem('refreshRedirectURI')
                localStorage.removeItem('refreshRedirectURI')

                if (redirect) {
                    appService.route(redirect)
                } else {
                    appService.routeWithinDomain('/dashboard')
                }
            }
        } catch (error) {
            handleLoadError(error)
        } finally {
            inAuth0Flow.current = false
        }
    }, [])

    const loadUserViaSession = React.useCallback(async (skipValidation: boolean = false) => {
        let user: UserDto
        try {
            const validatedUser = await validateSession()
            user = UserDto.fromApiResponse(validatedUser)

            await setupInitialUserContext(user!)
        } catch (error) {
            const wrappedError = new SessionValidationFailedException(error)
            handleLoadError(wrappedError)
        }
    }, [])

    const initializeApplication = React.useCallback(async () => {
        await appState.initializer
        setAppStateInitialized(true)
    }, [])

    const setupInitialUserContext = React.useCallback(async (user: UserDto) => {
        sendGAEvent('set_current_user', {}, appState)

        await refreshUserContext(user)

        appState.isAuthenticated = true
        appService.unsetAppLoading()

        setInitialUserContextLoaded(true)
    }, [])

    /**
     * Gets all user data and loads abilities
     * Can be called at anytime to refresh user context
     */
    const refreshUserContext = React.useCallback(async (user: UserDto) => {
        try {
            await appState.fetchUserDependencies()

            appState.currentUser = user
            await appState.abilityStore.loadAbilitiesForCurrentUser()

            setUserContext({
                authenticated: true,
                user,
            })

            // TODO: migrate appState user auto refresh here
        } catch (error) {
            setLoadError(error)
        }
    }, [])

    React.useEffect(() => {
        if (!inAuth0Flow.current && loc.pathname === AUTH0_PLATFORM_REDIRECT_PATHNAME) {
            inAuth0Flow.current = true

            const params = parseQueryStringParams()
            const code = params.get('code')?.trim()
            const state = params.get('state')?.trim()
            const stateSessionValue = window.sessionStorage.getItem(AUTH0_STATE_SESSION_KEY)

            if (code && state === stateSessionValue) {
                authorizeAndLoadUserViaAuth0Code(code)
            } else {
                setLoadError(new Auth0InvalidAuthorizationParamsException())
            }
        }
    }, [inAuth0Flow.current, loc.pathname])

    React.useEffect(() => {
        // prevent unnecessary revalidate user, until code has been traded for auth access token - causing early
        // unset loading states / behaviors
        if (!inAuth0Flow.current) {
            loadUserViaSession()
        }
    }, [inAuth0Flow.current])

    React.useEffect(() => {
        initializeApplication()
    }, [])

    if (loadError instanceof Auth0InvalidAuthorizationParamsException) {
        const qs = new URLSearchParams(loc.search)

        const auth0Error = qs.get('error')
        const auth0ErrorMessage = qs.get('error_description') ?? undefined

        /**
         * Adjust messaging for server_error responses from Auth0.
         */
        const code = auth0Error === 'server_error' ? 503 : 403
        const label = code === 503 ? auth0ErrorMessage : undefined
        const subLabel =
            code === 503 ? 'If the issue persists contact your account manager for more information.' : undefined

        return (
            <ErrorPage
                errorCode={code}
                showAction={true}
                hideLogo={code !== 503}
                label={label}
                subLabel={
                    subLabel ? (
                        <div style={{ fontSize: '1.25em' }}>
                            {subLabel}
                            <br />
                            <br />
                        </div>
                    ) : undefined
                }
            />
        )
    } else if (loadError && !(loadError instanceof SessionValidationFailedException)) {
        return <ErrorPage errorCode={403} showAction={true} hideLogo={true} />
    } else if (!appStateInitialized || !initialUserContextLoaded) {
        return <ModuleLoadingScreen loading={true} />
    }

    return (
        <PlatformUserContext.Provider value={{ ...userContext, assignUser: setUserContext }}>
            {children}
        </PlatformUserContext.Provider>
    )
}
