import * as React from 'react'
import * as CopyToClipboard from 'react-copy-to-clipboard'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { camelCase, snakeCase } from 'change-case'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Tooltip, DatePicker, Popover, Input, Button } from 'antd'
import { observe } from 'mobx'
import { AppState } from '../../../stores/app'
import { AppService } from '../../../services'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { arrayContains, simpleNotification } from '../../../_utils/utils'
import { InsightsService } from '../../../services/insights'
import { DomainDto } from '../../../dtos/domain'
import { SHORT_DATE_FORMAT } from '../../../constants'
import { TIME_RANGE_OPTIONS } from '../constants/time-range-options'
import { DATE_INCREMENT_DROPDOWN_OPTIONS } from '../constants/date-increment-dropdown-options'
import { DEFAULT_TYPE, TYPE_DROPDOWN_OPTIONS } from '../constants/type-dropdown-options'
import { DEFAULT_LEVEL, LEVEL_DROPDOWN_OPTIONS } from '../constants/level-dropdown-options'
import { BREAKDOWN_DROPDOWN_OPTIONS, DEFAULT_BREAKDOWN } from '../constants/breakdown-dropdown-options'
import { COLUMN_DROPDOWN_OPTIONS } from '../constants/column-dropdown-options'
import { FILTER_DROPDOWN_OPTIONS } from '../constants/filter-dropdown-options'
import { IReportConfig } from '../interfaces/report-config'
import { IReportStatus } from '../interfaces/report-status'
import { ReportStatusCode } from '../enums/report-status-code'
import './report-builder.scss'
import { FilterBuilder } from '../filter-builders/filter-builder'
import clone from 'clone'
import type { RangeValueRecord, RangeValueWithPreset } from '../../../types/range-value'
import { buildApiReportContract } from '../helpers/build-api-report-contract'
import { ColumnDropdownOption } from '../types'

const DEFAULT_REPORT_TIME_RANGE = TIME_RANGE_OPTIONS['Last 7 Days']
const FILTERS_ENABLED: boolean = true

let UPDATE_DEBOUNCE: any

interface IShareReportProps {
    reportConfig: IReportConfig
    visible: boolean
    panelRef?: any
}

interface IShareReportState {
    generatedReport?: any
}

export class ShareReportPanel extends BetterComponent<IShareReportProps, IShareReportState> {
    private appState: AppState
    private insightsService: InsightsService

    private textboxRef: any

    public constructor(props: IShareReportProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.insightsService = Container.get(InsightsService)

        this.state = {}
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.visible) {
            const globalTable = (window as any).__SW_REPORT_TABLE__ || {}
            const reportConfig = {
                ...this.props.reportConfig,
                tableSort: {
                    sortBy: globalTable.sortBy,
                    sortDirection: globalTable.sortDirection,
                },
            }

            const generatedReport = await this.insightsService.generateSharedReport(
                this.appState.currentDomain!.id,
                reportConfig,
                false,
                'generateSharedReport',
            )

            if (generatedReport) {
                this.setState({ generatedReport })

                if (!!this.textboxRef && !!this.textboxRef.textAreaRef) {
                    this.textboxRef.textAreaRef.select()
                }
            }
        }
    }

    public render(): React.ReactNode {
        const { generatedReport } = this.state

        let reportLink = ''
        if (generatedReport) {
            reportLink = `${this.appState.platformHost}/domains/${this.appState.currentDomain!.id}/insights?report_id=${
                generatedReport.id
            }`
        }

        return !generatedReport ? (
            <>
                <div className="loading-link-wrapper">
                    <LoadingOutlined spin={true} />
                    <span>Loading report link...</span>
                </div>
            </>
        ) : (
            <>
                <Input.TextArea
                    ref={(textbox) => (this.textboxRef = textbox)}
                    className="copy-disabled"
                    readOnly={true}
                    value={reportLink}
                    style={{
                        resize: 'none',
                    }}
                />

                <div className="align-right copy-report-actions">
                    <span>
                        {this.props.panelRef && (
                            <AsyncButton size="small" type="ghost" onClick={this.handleClose}>
                                <span>Cancel</span>
                            </AsyncButton>
                        )}
                    </span>
                    <span>
                        <CopyToClipboard
                            onCopy={() => {
                                simpleNotification('success', 'Report link has been copied.')
                                this.handleClose()
                            }}
                            text={reportLink}
                        >
                            <AsyncButton type="primary" size="small" onClick={() => {}}>
                                <span>Copy Report Link</span>
                            </AsyncButton>
                        </CopyToClipboard>
                    </span>
                </div>
            </>
        )
    }

    @autobind
    private async handleClose(): Promise<void> {
        if (!!this.props.panelRef) {
            this.props.panelRef.setPopupVisible(false)
        }
    }
}

interface IProps {
    onStatusUpdate: (status: IReportStatus) => any
    onInsightsUpdate: (insights: any, config: IReportConfig, contract: any) => any
}

interface IState {
    currentDomain: DomainDto
    savedReports: any[]
    currentSavedReport?: any
    reportConfig: IReportConfig
    showFilters: boolean
    lastCallSignature?: string
    loading?: boolean

    savePanelVisible: boolean
    sharePanelVisible: boolean

    updateKey?: string
    updating?: boolean
}

export class ReportBuilder extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private insightsService: InsightsService

    private disposeObservers: any
    private saveReportPanelRef: any
    private shareReportPanelRef: any

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)

        const defaultType = this.determineDefaultTypeValue()
        const defaultLevel = this.determineDefaultLevelValue(defaultType)
        const defaultBreakdown = this.determineDefaultBreakdownValue(defaultLevel)

        this.state = {
            currentDomain: this.appState.currentDomain!,
            showFilters: false,
            savedReports: [],
            reportConfig: {
                dateIncrement: 'DAY',
                dateRange: this.getDefaultReportDateRange(),
                type: defaultType,
                level: defaultLevel,
                breakdown: defaultBreakdown,
            },
            savePanelVisible: false,
            sharePanelVisible: false,
        }
    }

    public async componentDidMount(): Promise<void> {
        this.readReportFromQueryString()

        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()

        this.disposeObservers.forEach((f: any) => f())
    }

    public render() {
        const { reportConfig, savedReports, currentSavedReport } = this.state

        const type = this.getCurrentType()
        const level = this.getCurrentLevel()
        const breakdown = this.getCurrentBreakdown()

        const levelKey = `${type}-LVL`
        const breakdownKey = `${type}_${level}-BRK`
        const columnsKey = `${type}_${level}-COL`

        const typeOptions = this.determineTypeOptions()
        const levelOptions = this.determineLevelOptions()
        const defaultLevelOption = this.determineDefaultLevelValue()
        const breakdownOptions = this.determineBreakdownOptions()
        const defaultBreakdownOption = this.determineDefaultBreakdownValue()
        const columnOptions = this.determineColumnOptions()
        const defaultColumnOptions = this.determineDefaultColumnValues(columnOptions)

        const filters = this.state.reportConfig.filters || []
        const currentFilteredFields = filters.map((f) => f.field)
        const allFilterOptions = this.determineFilterOptions()
        const filterOptions = allFilterOptions.filter((f) => !arrayContains(currentFilteredFields, f.value))

        const actionsEnabled =
            !!reportConfig.type && !!reportConfig.level && !!reportConfig.breakdown && !!reportConfig.columns

        const filtersToggle = (
            <div className="fx-col fx-shrink report-filter-add">
                <span className={`toggle-filters-btn-wrapper${allFilterOptions.length === 0 ? ' disabled' : ''}`}>
                    <AsyncButton className="filter-add-btn" type="primary" size="small" onClick={this.handleFilterAdd}>
                        <span>+ Filter</span>
                    </AsyncButton>
                </span>
            </div>
        )

        return (
            <div
                // key by domain ensures column re-rendering
                // occurs to account for conditions
                key={this.state.currentDomain.domainKey}
                className="report-builder"
            >
                <div className="report-builder-upper">
                    <div className="fx-col-group">
                        <div className="fx-col">
                            <BetterSelect
                                className="report-type-header"
                                prefix="Report"
                                placeholder="Choose your report"
                                disableSearch={!savedReports || savedReports.length < 4}
                                onChange={this.setReportTypeState}
                                options={typeOptions}
                                value={[!!currentSavedReport ? currentSavedReport.id : reportConfig.type]}
                            />
                        </div>
                    </div>
                    <div className="fx-col-group content-right">
                        <div className="fx-col fx-shrink">
                            <DatePicker.RangePicker
                                className="calendar-header"
                                dropdownClassName="reporting-date-range"
                                disabled={reportConfig.type === 'MONTHLY_UNIQUES'}
                                getPopupContainer={this.appService.getAppContainer}
                                format={SHORT_DATE_FORMAT}
                                // @ts-ignore -- issue with ALL_TIME [null, null] value accept
                                ranges={
                                    reportConfig.type === 'MONTHLY_UNIQUES'
                                        ? TIME_RANGE_OPTIONS['All Time']
                                        : (TIME_RANGE_OPTIONS as RangeValueRecord<Moment>)
                                }
                                defaultValue={this.defaultReportTimeRange}
                                // @ts-ignore -- issue with ALL_TIME [null, null] value accept
                                value={
                                    reportConfig.type === 'MONTHLY_UNIQUES'
                                        ? TIME_RANGE_OPTIONS['All Time']
                                        : !reportConfig.dateRange
                                        ? undefined
                                        : [
                                              !reportConfig.dateRange.since
                                                  ? undefined
                                                  : moment(reportConfig.dateRange.since),
                                              !reportConfig.dateRange.through
                                                  ? undefined
                                                  : moment(reportConfig.dateRange.through),
                                          ]
                                }
                                onCalendarChange={this.setDateRangeState}
                            />
                        </div>
                        <div className="fx-col fx-shrink">
                            <BetterSelect
                                className="report-date-increment"
                                prefix="Date Grouping"
                                disabled={reportConfig.type === 'MONTHLY_UNIQUES'}
                                disableSearch={true}
                                onChange={this.setDateIncrementState}
                                options={DATE_INCREMENT_DROPDOWN_OPTIONS[type || 'NONE']}
                                value={[reportConfig.dateIncrement]}
                            />
                        </div>
                    </div>
                </div>

                <div className="report-builder-lower">
                    <div className="fx-col-group">
                        <div className="fx-col report-level-col">
                            <BetterSelect
                                key={levelKey}
                                _key={levelKey}
                                className="report-level-select"
                                size="small"
                                prefix="Level"
                                placeholder="Select a level"
                                skeletonMode={!reportConfig.type}
                                disabled={reportConfig.type === 'MONTHLY_UNIQUES'}
                                disableSearch={true}
                                onChange={this.setReportLevelState}
                                options={levelOptions}
                                defaultValue={defaultLevelOption ? [defaultLevelOption] : []}
                                value={[reportConfig.level]}
                            />
                        </div>
                        <div className="fx-col report-breakdown-col">
                            <BetterSelect
                                key={breakdownKey}
                                _key={breakdownKey}
                                className="report-breakdown-select"
                                size="small"
                                prefix="Breakdown"
                                skeletonMode={!reportConfig.level}
                                disabled={reportConfig.level === 'TEST'}
                                disabledValue="None"
                                disableSearch={true}
                                onChange={this.setReportBreakdownState}
                                options={breakdownOptions}
                                defaultValue={defaultBreakdownOption ? [defaultBreakdownOption] : []}
                                value={[reportConfig.breakdown]}
                            />
                        </div>
                        <div className="fx-col report-columns-col">
                            <BetterSelect
                                key={columnsKey}
                                _key={columnsKey}
                                className="report-columns-select"
                                dropdownClassName="report-columns-select-dropdown"
                                size="small"
                                prefix="Columns"
                                mode="multiple"
                                skeletonMode={!reportConfig.level}
                                disableSearch={true}
                                onChange={this.setReportColumnsState}
                                onClose={() => this.handleConfigChange()}
                                options={columnOptions}
                                defaultValue={defaultColumnOptions}
                                maxDisplayCount={1}
                                maxDisplayFormatter={(opts) => `${opts.length} selected`}
                                value={reportConfig.columns}
                            />
                        </div>
                    </div>
                    <div className="fx-col-group content-right">
                        {/*<div className="fx-col-divider" />*/}

                        {!!currentSavedReport && (
                            <div className="fx-col fx-shrink">
                                {this.renderActionButton(
                                    currentSavedReport.isDefault ? 'star:filled' : 'star',
                                    'set-default-btn',
                                    this.handleSetDefaultReport,
                                    !actionsEnabled,
                                    currentSavedReport.isDefault ? 'Unmark as Default' : 'Mark as Default',
                                    undefined,
                                    currentSavedReport.isDefault ? '#FFBF00' : undefined,
                                )}
                            </div>
                        )}
                        <div className="fx-col fx-shrink">
                            {this.renderActionButton(
                                'save',
                                'save-btn',
                                () => {},
                                !actionsEnabled,
                                'Save Report',
                                (btn) => (
                                    <Popover
                                        ref={(panel) => (this.saveReportPanelRef = panel)}
                                        title="Save Report"
                                        content={this.renderSaveReportPanel()}
                                        trigger="click"
                                        placement="bottomRight"
                                        getPopupContainer={this.appService.getAppContainer}
                                        overlayClassName="save-report-overlay"
                                        onVisibleChange={(savePanelVisible) =>
                                            this.setState({
                                                savePanelVisible,
                                            })
                                        }
                                    >
                                        {btn}
                                    </Popover>
                                ),
                            )}
                        </div>
                        <div className="fx-col-divider" />
                        <div className="fx-col fx-shrink">
                            {this.renderActionButton(
                                'share-alt',
                                'share-btn',
                                () => {},
                                !actionsEnabled,
                                'Share Report',
                                (btn) => (
                                    <Popover
                                        ref={(panel) => (this.shareReportPanelRef = panel)}
                                        title="Share Report"
                                        content={this.renderShareReportPanel()}
                                        trigger="click"
                                        placement="bottomRight"
                                        getPopupContainer={this.appService.getAppContainer}
                                        overlayClassName="copy-report-overlay"
                                        onVisibleChange={(sharePanelVisible) =>
                                            this.setState({
                                                sharePanelVisible,
                                            })
                                        }
                                    >
                                        {btn}
                                    </Popover>
                                ),
                            )}
                        </div>
                        <div className="fx-col fx-shrink report-download-button">
                            {this.renderReportDownloadButton()}
                        </div>
                        <div className="fx-col-divider" />
                        <div className="fx-col fx-shrink">
                            <Tooltip title="Refresh">
                                <Button
                                    className="refresh-report"
                                    size="small"
                                    shape="round"
                                    onClick={this.handleRefresh}
                                >
                                    <ReloadOutlined spin={this.state.updating} />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {FILTERS_ENABLED && (
                    <div className="report-builder-lower-ext">
                        <div className="fx-col-group">
                            {filters.map((filter, idx) => {
                                const { $mode, ...filterProps } = filter as any

                                return (
                                    <div
                                        key={`filter-builder${idx}-col`}
                                        className="fx-col fx-shrink report-filter-col"
                                    >
                                        <FilterBuilder
                                            initialMode={$mode}
                                            reportType={type}
                                            reportLevel={level}
                                            reportBreakdown={breakdown}
                                            allTypeOptions={allFilterOptions}
                                            availableTypeOptions={filterOptions}
                                            value={filter}
                                            onChange={(update) => this.handleFilterUpdate(update, idx)}
                                            onRemove={() => this.handleFilterRemove(idx)}
                                        />
                                    </div>
                                )
                            })}
                            {allFilterOptions.length > 0 ? (
                                filters.length !== allFilterOptions.length && filtersToggle
                            ) : (
                                <Tooltip title="No filters currently available">{filtersToggle}</Tooltip>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    public get defaultReportTimeRange(): [any, any] {
        return DEFAULT_REPORT_TIME_RANGE() as [any, any]
    }

    public renderActionButton(
        icon: any,
        className: string,
        onClick: () => any,
        disabled: boolean = false,
        tooltip?: React.ReactNode,
        renderPopover?: (children: React.ReactNode) => React.ReactNode,
        color?: string,
    ): React.ReactNode {
        const button = (
            <AsyncButton
                className={className}
                size="small"
                icon={icon}
                disabled={disabled}
                onClick={onClick}
                color={color}
            />
        )

        const wrappedButton = !!renderPopover ? renderPopover(button) : button

        return !disabled && !!tooltip ? <Tooltip title={tooltip}>{wrappedButton}</Tooltip> : wrappedButton
    }

    public renderReportDownloadButton(): React.ReactNode {
        const actions = [
            {
                text: 'Download CSV Report',
                onClick: () => this.handleDownload('csv'),
            },
        ]

        const button = (
            <AsyncButton
                actions={actions}
                className="download-btn"
                size="small"
                icon="cloud-download"
                disabled={false}
                onClick={() => this.handleDownload('xlsx')}
            />
        )

        return <Tooltip title="Download Excel Report">{button}</Tooltip>
    }

    protected renderSaveReportPanel(): React.ReactNode {
        const { savedReports = [], currentSavedReport, savePanelVisible } = this.state

        let inputRef: any
        const isDefault = (currentSavedReport || {}).isDefault

        return (
            <>
                <Form>
                    <Input
                        key={`visible-${savePanelVisible}`}
                        ref={(el) => (inputRef = el)}
                        className="new-report-name-input"
                        defaultValue={(currentSavedReport || {}).name}
                        type="text"
                        addonBefore="Name:"
                        maxLength={240}
                        autoFocus={true}
                    />

                    <div className="align-right save-report-actions">
                        <span>
                            <AsyncButton size="small" type="ghost" onClick={this.handleCloseSave}>
                                <span>Cancel</span>
                            </AsyncButton>
                        </span>
                        <span>
                            <AsyncButton
                                size="small"
                                type="primary"
                                onClick={() =>
                                    this.handleSave((currentSavedReport || {}).id, inputRef.input.value, isDefault)
                                }
                                actions={
                                    !currentSavedReport
                                        ? undefined
                                        : [
                                              {
                                                  text: 'Save New',
                                                  onClick: () => this.handleSave(undefined, inputRef.input.value),
                                              },
                                          ]
                                }
                            >
                                <span>{!currentSavedReport ? 'Save' : 'Update'}</span>
                            </AsyncButton>
                        </span>
                    </div>
                </Form>
            </>
        )
    }

    protected renderShareReportPanel(): React.ReactNode {
        return (
            <ShareReportPanel
                key={String(this.state.sharePanelVisible)}
                visible={this.state.sharePanelVisible}
                reportConfig={this.state.reportConfig}
                panelRef={this.shareReportPanelRef}
            />
        )
    }

    protected getCurrentType(): string {
        const { reportConfig, savedReports } = this.state

        let type = reportConfig.type || 'NONE'
        if (typeof type !== 'string') {
            type = savedReports.find((sr) => sr.id === type).reportConfig.type
        }

        return type
    }

    protected getCurrentLevel(): string {
        const { reportConfig } = this.state

        return reportConfig.level || 'NONE'
    }

    protected getCurrentBreakdown(): string {
        const { reportConfig } = this.state

        return reportConfig.breakdown || 'NONE'
    }

    protected determineTypeOptions(): any[] {
        const { savedReports } = this.state
        let defaultOptions = clone(TYPE_DROPDOWN_OPTIONS)

        defaultOptions.forEach((opt) => {
            opt.options = opt.options.filter((o) => o.condition?.(this.state.currentDomain!) ?? true)
        })

        const typeOptions = [...defaultOptions]

        if (!!savedReports && savedReports.length > 0) {
            typeOptions.push({
                label: 'Saved',
                options: savedReports.map((sr) => ({
                    value: sr.id,
                    label: sr.name,
                })),
            })
        }

        return typeOptions
    }

    protected determineDefaultTypeValue(): string | undefined {
        const stub: any = {}
        const { reportConfig = stub } = this.state || stub

        return !reportConfig.type ? DEFAULT_TYPE.value : reportConfig.level || DEFAULT_TYPE.value
    }

    protected determineLevelOptions(type?: string): any[] {
        const { reportConfig } = this.state

        type = type || this.getCurrentType()

        const options = !reportConfig.type ? [] : LEVEL_DROPDOWN_OPTIONS[type!] || []

        return options.filter((opt) => {
            return opt.condition?.(type!, this.state.currentDomain) ?? true
        })
    }

    protected determineDefaultLevelValue(type?: string): string | undefined {
        const stub: any = {}
        const { reportConfig = stub } = this.state || stub

        return !(type || reportConfig.type) ? undefined : reportConfig.level || DEFAULT_LEVEL.value
    }

    protected determineBreakdownOptions(type?: string, level?: string): any[] {
        const { reportConfig } = this.state

        type = type || this.getCurrentType()
        level = level || this.getCurrentLevel()

        const options = !reportConfig.level ? [] : BREAKDOWN_DROPDOWN_OPTIONS[type + '_' + level] || []

        return options.filter((opt) => {
            return opt.condition?.(type!, level!, this.state.currentDomain) ?? true
        })
    }

    protected determineDefaultBreakdownValue(level?: string): string | undefined {
        const stub: any = {}
        const { reportConfig = stub } = this.state || stub

        return !(level || reportConfig.level) ? undefined : reportConfig.breakdown || DEFAULT_BREAKDOWN.value
    }

    protected determineColumnOptions(type?: string, level?: string, breakdown?: string): any[] {
        const { reportConfig } = this.state

        type = type || this.getCurrentType()
        level = level || this.getCurrentLevel()
        breakdown = breakdown || this.getCurrentBreakdown()

        const optionCategories = !reportConfig.level ? [] : COLUMN_DROPDOWN_OPTIONS[type + '_' + level] || []

        return optionCategories.map((cat) => {
            return {
                ...cat,
                options: cat.options.filter((opt) => {
                    return opt.condition?.(type!, level!, breakdown!, this.state.currentDomain) ?? true
                }),
            }
        })
    }

    protected determineDefaultColumnValues(columnOptions: ColumnDropdownOption[]): string[] {
        const dims: ColumnDropdownOption[] =
            (columnOptions.find((opt) => opt.label.toUpperCase() === 'DIMENSIONS') || ({} as any)).options || []
        const measures: ColumnDropdownOption[] =
            (columnOptions.find((opt) => opt.label.toUpperCase() === 'MEASURES') || ({} as any)).options || []

        return [...dims.filter((opt) => opt.default === true), ...measures.filter((opt) => opt.default === true)].map(
            (opt) => opt.value,
        )
    }

    protected determineFilterOptions(type?: string, level?: string, breakdown?: string, columns?: string[]): any[] {
        const { reportConfig } = this.state

        type = type || this.getCurrentType()
        level = level || this.getCurrentLevel()
        breakdown = breakdown || this.getCurrentBreakdown()
        columns = (columns || this.state.reportConfig.columns || []).map((c) => c.replace('.', '_'))

        const filterSelector =
            type + '_' + (level || reportConfig.level || 'NONE') + '_' + (breakdown || reportConfig.breakdown || 'NONE')

        const options = !reportConfig.level ? [] : FILTER_DROPDOWN_OPTIONS[filterSelector] || []

        const filteredOptions = options.filter((opt) => !opt.columnRequired || columns!.includes(opt.value))

        filteredOptions.sort((a, b) => {
            const aName = (a.label || a.value).toLowerCase()
            const bName = (b.label || b.value).toLowerCase()
            return aName > bName ? 1 : aName < bName ? -1 : 0
        })

        return filteredOptions
    }

    @autobind
    protected async setDomainState(): Promise<void> {
        const currentDomain = this.state.currentDomain || ({} as any)
        const globalDomain = this.globalDomain

        if (globalDomain) {
            if (currentDomain.id !== globalDomain.id) {
                await this.setState(({ reportConfig }) => ({
                    currentDomain: globalDomain,
                    currentSavedReport: undefined,
                    showFilters: false,
                    reportConfig: {
                        ...reportConfig,
                        filters: undefined,
                    },
                }))
            }
        }

        await this.fetchSavedReports()

        return this.handleConfigChange(true, 'LOAD_SAVED_REPORT')
    }

    protected async fetchSavedReports(): Promise<void> {
        const globalDomain = this.globalDomain

        if (globalDomain) {
            this.appService.setModuleLoading()

            const savedReports = await this.insightsService.getSavedReports(
                globalDomain.id,
                this.appState.currentUser!.id,
                false,
                'fetchSavedReports',
            )

            const defaultReport = (savedReports || []).find((sr: any) => sr.isDefault)

            let requestedReport: any
            if (this.queryString.report_id) {
                const shared = this.queryString.shared?.toString().trim() !== '0'

                try {
                    requestedReport = await this.insightsService.loadReportFromLink(
                        globalDomain.id,
                        this.queryString.report_id as any,
                        shared,
                        false,
                        'fetchSharedReport',
                    )
                } catch (err) {}
            }

            await this.setState(({ reportConfig }) => {
                const newState: any = {
                    savedReports: this.sortSavedReports(savedReports),
                    reportConfig,
                }

                if (requestedReport) {
                    newState.reportConfig = requestedReport.reportConfig
                } else if (defaultReport) {
                    if (defaultReport.reportConfig.dateRangePresetKey) {
                        defaultReport.reportConfig.dateRange = this.getRangeFromPresetKey(
                            defaultReport.reportConfig.dateRangePresetKey,
                        )
                    }

                    newState.currentSavedReport = defaultReport
                    newState.reportConfig = defaultReport.reportConfig
                }

                return newState
            })

            if (defaultReport || requestedReport) {
                const loadedReport = requestedReport || defaultReport

                if (loadedReport.reportConfig.tableSort) {
                    this.triggerForcedReportSort(loadedReport.reportConfig.tableSort)
                }
            }

            this.appService.unsetModuleLoading()
        }
    }

    protected getRangeFromPresetKey(presetKey: string): { since: string; through: string } {
        const rangeKeys = Object.keys(TIME_RANGE_OPTIONS)
        const timeRangeKey = rangeKeys.find((key) => TIME_RANGE_OPTIONS[key]()!.includes(presetKey))
        const range = TIME_RANGE_OPTIONS[timeRangeKey!]()!

        return {
            since: range[0]?.format('YYYY-MM-DD') ?? undefined!,
            through: range[1]?.format('YYYY-MM-DD') ?? undefined!,
        }
    }

    protected triggerForcedReportSort(detail: any): void {
        window.__SW_REPORT_TABLE__ = {
            ...(window.__SW_REPORT_TABLE__ ?? {}),
            ...detail,
        }

        window.dispatchEvent(new CustomEvent('sw-force-report-sort', { detail }))
    }

    protected sortSavedReports(reports: any[]): any[] {
        return reports.sort((a: any, b: any) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        })
    }

    @autobind
    protected async setShowFiltersState(): Promise<void> {
        await this.setState(({ showFilters, reportConfig }) => {
            const newState: any = {
                showFilters: !showFilters,
            }

            if (!newState.showFilters) {
                newState.reportConfig = {
                    ...reportConfig,
                    filters: undefined,
                }
            }

            return newState
        })

        this.handleConfigChange()
    }

    @autobind
    protected async setDateIncrementState(dateIncrement: string): Promise<void> {
        await this.setState(({ reportConfig }) => ({
            reportConfig: {
                ...reportConfig,
                dateIncrement,
            },
        }))

        this.handleConfigChange()
    }

    @autobind
    protected async setDateRangeState(values: RangeValueWithPreset<Moment>): Promise<void> {
        const dateRangePresetKey = values?.length === 3 ? values[2] : undefined

        const dateRange: any = {}
        dateRange.since = !values?.[0] ? undefined : values[0].format('YYYY-MM-DD')
        dateRange.through = !values?.[1] ? undefined : values[1].format('YYYY-MM-DD')

        await this.setState(({ reportConfig }) => ({
            reportConfig: {
                ...reportConfig,
                dateRange,
                dateRangePresetKey,
            },
        }))

        this.handleConfigChange()
    }

    @autobind
    protected async setReportTypeState(type: string): Promise<void> {
        if (typeof type !== 'string') {
            await this.setCurrentSavedReportState(type)
        } else {
            await this.setState(({ reportConfig, updateKey, updating }) => {
                const useSavedReportValues = updateKey === 'LOAD_SAVED_REPORT' && updating
                const columnOptions = this.determineColumnOptions(type, DEFAULT_LEVEL.value)
                const columnSelections = this.determineDefaultColumnValues(columnOptions)

                if (type === 'MONTHLY_UNIQUES') {
                    this.setDateRangeState(TIME_RANGE_OPTIONS['All Time']())
                } else if (reportConfig.type === 'MONTHLY_UNIQUES') {
                    this.setDateRangeState(TIME_RANGE_OPTIONS['Last 7 Days']())
                }

                return {
                    currentSavedReport: undefined,
                    reportConfig: {
                        type,
                        level: useSavedReportValues ? reportConfig.level : DEFAULT_LEVEL.value,
                        dateRange: reportConfig.dateRange,
                        dateIncrement:
                            type === 'DELIVERY'
                                ? reportConfig.dateIncrement === 'LIFETIME'
                                    ? 'YEAR'
                                    : reportConfig.dateIncrement
                                : type === 'MONTHLY_UNIQUES'
                                ? 'MONTH'
                                : reportConfig.dateIncrement,
                        breakdown: useSavedReportValues ? reportConfig.breakdown : DEFAULT_BREAKDOWN.value,
                        columns: useSavedReportValues ? reportConfig.columns : columnSelections,
                        filters: useSavedReportValues ? reportConfig.filters : undefined,
                    },
                }
            })
        }

        this.handleConfigChange()
    }

    @autobind
    protected async setReportLevelState(level: string): Promise<void> {
        if (level !== this.state.reportConfig.level) {
            await this.setState(({ reportConfig, updateKey, updating }) => {
                const useSavedReportValues = updateKey === 'LOAD_SAVED_REPORT' && updating

                const columnOptions = this.determineColumnOptions(reportConfig.type, level)
                const defaultColumnOptions = this.determineDefaultColumnValues(columnOptions)

                return {
                    reportConfig: {
                        ...reportConfig,
                        level,
                        breakdown: useSavedReportValues ? reportConfig.breakdown : DEFAULT_BREAKDOWN.value,
                        columns: useSavedReportValues ? reportConfig.columns : defaultColumnOptions,
                        filters: useSavedReportValues ? reportConfig.filters : undefined,
                    },
                }
            })
        }

        this.handleConfigChange()
    }

    @autobind
    protected async setReportBreakdownState(breakdown: string): Promise<void> {
        if (breakdown !== this.state.reportConfig.breakdown) {
            await this.setState(({ reportConfig, updateKey, updating }) => {
                const useSavedReportValues = updateKey === 'LOAD_SAVED_REPORT' && updating
                const availableFilters = this.determineFilterOptions(undefined, undefined, breakdown)
                const availableFilterFields = availableFilters.map((f) => f.value)
                const filters = reportConfig.filters || []
                const allowedFilters = useSavedReportValues
                    ? filters
                    : filters.filter((f) => availableFilterFields.includes(f.field))

                const config = {
                    ...reportConfig,
                    breakdown,
                    columns: reportConfig.columns || [],
                    filters: allowedFilters.length === 0 ? undefined : allowedFilters,
                }

                if (breakdown === 'BUTTON') {
                    config.columns.push('BUTTON.TYPE')
                    config.columns.push('BUTTON.LABEL')
                } else {
                    config.columns = config.columns.filter((col) => !/^button/i.test(col))
                }

                return {
                    reportConfig: config,
                }
            })
        }

        this.handleConfigChange()
    }

    @autobind
    protected async setReportColumnsState(columns: string[]): Promise<void> {
        await this.setState(({ reportConfig }) => {
            return {
                reportConfig: {
                    ...reportConfig,
                    columns,
                },
            }
        })
    }

    // FILTERSv2
    protected handleFilterAdd = async () => {
        return this.setState(({ reportConfig }) => ({
            reportConfig: {
                ...reportConfig,
                filters: [
                    ...(reportConfig.filters || []),
                    {
                        $mode: 'new',
                    } as any,
                ],
            },
        }))
    }

    protected handleFilterUpdate = async (update, filterIdx) => {
        if (filterIdx !== -1) {
            await this.setState(({ reportConfig }) => {
                const filtersCopy = clone(reportConfig.filters || [])
                filtersCopy.splice(filterIdx, 1, update)

                return {
                    reportConfig: {
                        ...reportConfig,
                        filters: filtersCopy,
                    },
                }
            })

            if (!!update.value) {
                this.handleConfigChange()
            }
        }
    }

    protected handleFilterRemove = async (filterIdx) => {
        await this.setState(({ reportConfig }) => {
            const filtersCopy = clone(reportConfig.filters || [])
            filtersCopy.splice(filterIdx, 1)

            return {
                reportConfig: {
                    ...reportConfig,
                    filters: filtersCopy,
                },
            }
        })

        this.handleConfigChange()
    }
    //

    @autobind
    protected async setReportFiltersState(filters?: any[]): Promise<void> {
        await this.setState(({ reportConfig }) => {
            const state: Partial<IState> = {
                reportConfig: {
                    ...reportConfig,
                    filters,
                },
            }

            if (!filters) {
                state.showFilters = false
            }

            return state as IState
        })

        this.handleConfigChange()
    }

    @autobind
    protected async setReportQueryState(query: string): Promise<void> {
        await this.setState(({ reportConfig }) => ({
            reportConfig: {
                ...reportConfig,
                query,
            },
        }))

        this.handleConfigChange()
    }

    @autobind
    protected async setCurrentSavedReportState(reportId: number): Promise<void> {
        const { savedReports = [] } = this.state
        const report = savedReports.find((r: any) => r.id === reportId)

        if (report) {
            await this.setState(({ reportConfig }) => ({
                currentSavedReport: report,
                reportConfig: {
                    ...reportConfig,
                    ...report.reportConfig,
                },
                showFilters: (report.reportConfig.filters || []).length > 0,
            }))

            if (!!report.reportConfig.tableSort) {
                this.triggerForcedReportSort(report.reportConfig.tableSort)
            }

            return this.handleConfigChange(true, 'LOAD_SAVED_REPORT')
        }
    }

    protected getDefaultReportDateRange(): any {
        const range = this.defaultReportTimeRange

        return {
            since: range[0].format('YYYY-MM-DD'),
            through: range[1].format('YYYY-MM-DD'),
        }
    }

    @autobind
    protected async handleDownload(format: 'csv' | 'xlsx'): Promise<void> {
        const { reportConfig, currentSavedReport } = this.state
        const type = reportConfig.type!.toLowerCase()
        const level = reportConfig.level!.toLowerCase()
        const breakdown = reportConfig
            .breakdown!.toLowerCase()
            .split(',')
            .map((bd) => (bd === 'placement' ? 'device-type' : bd))
            .filter((bd) => bd !== 'none')
            .join('-')

        const contract = buildApiReportContract(
            reportConfig,
            this.globalDomain!.id,
            this.getCurrentType(),
            this.getCurrentLevel(),
            true,
        )

        contract.format = format
        contract.filename = !!currentSavedReport
            ? snakeCase(currentSavedReport.name)
            : `${type}_${level}${!!breakdown ? '_' + breakdown : ''}`

        if (!currentSavedReport && !!reportConfig.dateRange && !!reportConfig.dateRange.since) {
            const range: any = reportConfig.dateRange
            const since = range.since.replace(/-/gi, '')
            const through = range.through.replace(/-/gi, '')

            contract.filename = `${contract.filename}_${since}-${through}`
        }

        contract.filename = contract.filename + '_report'

        // CSV download exclusions
        const exclusions = ['domain.id', 'domain.name', 'group.id', 'group.name', 'notification.source']
        exclusions.forEach((exclusion) => {
            const exclusionIndex = contract.fields.findIndex((f: string) => f === exclusion)
            if (exclusionIndex !== -1) {
                contract.fields.splice(exclusionIndex, 1)
            }
        })

        try {
            this.fetchInsights(contract, true)
        } catch (err) {
            this.sendErrorStatus(err)
        }
    }

    @autobind
    protected async handleRefresh(): Promise<void> {
        await this.setState({ updating: true })

        await this.updateData(undefined, true)

        await this.setState({ updating: false })
    }

    @autobind
    protected async handleCloseSave(...args: any[]): Promise<void> {
        if (!!this.saveReportPanelRef) {
            this.saveReportPanelRef.setPopupVisible(false)
        }
    }

    @autobind
    protected async handleSave(
        reportId: number | undefined,
        reportName: string,
        isDefault: boolean = false,
    ): Promise<void> {
        const reportConfig = clone(this.state.reportConfig)
        const globalTable = (window as any).__SW_REPORT_TABLE__ || {}

        // XOR preset or range
        if (!!reportConfig.dateRangePresetKey) {
            delete reportConfig.dateRange
        } else {
            delete reportConfig.dateRangePresetKey
        }

        reportConfig.tableSort = {
            sortBy: globalTable.sortBy,
            sortDirection: globalTable.sortDirection,
        }

        const trimmedReportName = reportName.toString().trim()
        if (!reportName || !trimmedReportName) {
            simpleNotification('error', 'Report name cannot be empty.', undefined, 'saveUpdateReport')
            return
        }

        reportConfig.filters = this.parseExecutableFilters(reportConfig.filters)

        const savedReport = await this.insightsService.save(
            this.appState.currentDomain!.id,
            this.appState.currentUser!.id,
            reportId,
            trimmedReportName,
            isDefault,
            reportConfig,
        )

        if (savedReport) {
            simpleNotification(
                'success',
                reportId
                    ? `${trimmedReportName} has been successfully updated.`
                    : `${trimmedReportName} has been successfully saved.`,
                undefined,
                'saveUpdateReport',
            )

            await this.setState(({ savedReports, currentSavedReport }) => {
                const update = {
                    currentSavedReport: savedReport,
                    savedReports,
                }

                if (currentSavedReport && savedReport.id === currentSavedReport.id) {
                    const currentReportIndex = savedReports.findIndex((r: any) => r.id === reportId)
                    if (currentReportIndex !== -1) {
                        update.savedReports.splice(currentReportIndex, 1, savedReport)
                    }
                } else {
                    update.savedReports = [...update.savedReports, savedReport]

                    update.savedReports = this.sortSavedReports(update.savedReports)
                }

                return update
            })

            this.handleCloseSave()
        }
    }

    protected parseExecutableFilters(filters: any[] | undefined): any[] | undefined {
        if (!filters) {
            return filters
        }
        const executable = clone(filters)

        return executable
            .map((f) => {
                delete f.$mode
                return f
            })
            .filter(
                (f) =>
                    !!f.field &&
                    !!f.operator &&
                    f.value !== null &&
                    f.value !== undefined &&
                    f.value.toString().trim() !== '',
            )
    }

    @autobind
    protected async handleSetDefaultReport(): Promise<void> {
        const { reportConfig, currentSavedReport: csr } = this.state

        const savedReport = await this.insightsService.save(
            this.appState.currentDomain!.id,
            this.appState.currentUser!.id,
            csr.id,
            csr.name,
            !csr.isDefault,
            reportConfig,
            true,
        )

        if (!!savedReport) {
            simpleNotification(
                'success',
                savedReport.isDefault
                    ? `${savedReport.name} has been set as your default report.`
                    : `${savedReport.name} has been unset as your default report.`,
                undefined,
                'setDefaultReport',
            )

            this.setState(({ savedReports }) => {
                const update = {
                    currentSavedReport: savedReport,
                    savedReports,
                }

                const lastDefaultIndex = savedReports.findIndex((r: any) => r.isDefault)
                if (lastDefaultIndex !== -1) {
                    const lastDefault = savedReports.find((r: any) => r.isDefault)
                    lastDefault.isDefault = false
                    update.savedReports.splice(lastDefaultIndex, 1, lastDefault)
                }

                const currentReportIndex = savedReports.findIndex((r: any) => r.id === csr.id)
                if (currentReportIndex !== -1) {
                    update.savedReports.splice(currentReportIndex, 1, savedReport)
                }

                update.savedReports = this.sortSavedReports(update.savedReports)

                return update
            })
        }
    }

    @autobind
    protected async handleConfigChange(
        fullPathUpdate: boolean = false,
        updateKey: string = 'updateKey',
    ): Promise<void> {
        if (UPDATE_DEBOUNCE) {
            clearTimeout(UPDATE_DEBOUNCE)
        }

        if (!this.state.updating) {
            this.setState({ updateKey, updating: true })
        }

        UPDATE_DEBOUNCE = setTimeout(async () => {
            const { reportConfig } = this.state

            const canProcessConfig =
                !!reportConfig.type && !!reportConfig.level && !!reportConfig.breakdown && !!reportConfig.columns

            if (canProcessConfig) {
                this.updatePathConfig(reportConfig, fullPathUpdate)

                if (!this.state.showFilters && (reportConfig.filters || []).length > 0) {
                    this.setState({ showFilters: true })
                }

                await this.updateData(reportConfig)
            } else {
                if (!reportConfig.type) {
                    this.sendSelectTypeStatus()
                } else if (!reportConfig.level) {
                    this.sendSelectLevelStatus()
                }
            }

            this.setState({ updating: false })
        }, 50)
    }

    protected sendErrorStatus(err: Error): void {
        this.props.onStatusUpdate({
            code: ReportStatusCode.ERROR,
            message: (
                <span>
                    An error has occured while generating your report.
                    <br />
                    Please contact your account manager for assistance.
                </span>
            ),
        })
    }

    protected sendSelectTypeStatus(): void {
        this.props.onStatusUpdate({
            code: ReportStatusCode.NOT_READY,
            message: (
                <span>
                    Please select your desired report <b>Type</b> to get started.
                </span>
            ),
        })
    }

    protected sendSelectLevelStatus(): void {
        this.props.onStatusUpdate({
            code: ReportStatusCode.NOT_READY,
            message: (
                <span>
                    Please select your desired report <b>Level</b> to continue.
                </span>
            ),
        })
    }

    protected sendLoadingStatus(): void {
        this.props.onStatusUpdate({
            code: ReportStatusCode.LOADING,
        })
    }

    protected sendReadyStatus(data: any[]): void {
        const hasData = data.length > 0
        this.props.onStatusUpdate({
            code: hasData ? ReportStatusCode.READY : ReportStatusCode.NO_DATA,
            message: hasData ? void 0 : <span>No data found for the specified report configuration.</span>,
        })
    }

    protected async updateData(config?: any, forceUpdate: boolean = false): Promise<void> {
        this.sendLoadingStatus()
        config = config || this.state.reportConfig
        const contract = buildApiReportContract(
            config,
            this.globalDomain!.id,
            this.getCurrentType(),
            this.getCurrentLevel(),
        )

        const contractSig = btoa(unescape(encodeURIComponent(JSON.stringify(contract))))

        if (forceUpdate || contractSig !== this.state.lastCallSignature) {
            await this.setState({ lastCallSignature: contractSig })

            try {
                contract.filters = this.parseExecutableFilters(contract.filters)

                const data = await this.fetchInsights(contract)
                this.props.onInsightsUpdate(data, config, contract)

                this.sendReadyStatus(data)
            } catch (err) {
                this.sendErrorStatus(err)
            }
        }
    }

    protected async fetchInsights(contract: any, showLoadingScreen: boolean = true): Promise<any> {
        const cleanContract = clone(contract)
        delete cleanContract.addtRequiredFields

        return this.insightsService.fetch(cleanContract, showLoadingScreen, 'insights-request')
    }

    protected async readReportFromQueryString(): Promise<void> {
        const { reportConfig: stateConfig } = this.state
        const allowedKeys = ['type', 'level', 'breakdown', 'dateRange', 'dateIncrement', 'columns', 'filters']

        const QUERY_UPDATES_ENABLED = false
        if (!QUERY_UPDATES_ENABLED) {
            return
        }

        if (!!this.queryString) {
            if (!this.queryString.report) {
                this.sendSelectTypeStatus()

                if (!!stateConfig.type && !!stateConfig.level && !!stateConfig.breakdown) {
                    // RESET FORM
                    await this.setState({
                        reportConfig: {
                            dateIncrement: 'DAY',
                            dateRange: this.getDefaultReportDateRange(),
                        },
                    })
                }
            } else {
                const currentConfig: any = {}
                const requestedConfig: any = {}

                try {
                    const parsedConfigReq = JSON.parse(this.queryString.report as any)
                    const reqKeys = Object.keys(parsedConfigReq).map((k) => camelCase(k))

                    reqKeys.forEach((reqKey) => {
                        if (allowedKeys.indexOf(reqKey) !== -1) {
                            currentConfig[reqKey] = stateConfig[reqKey]
                            requestedConfig[reqKey] = parsedConfigReq[reqKey]
                        }
                    })

                    const currentSignature = btoa(unescape(encodeURIComponent(JSON.stringify(currentConfig))))
                    const requestedSignature = btoa(unescape(encodeURIComponent(JSON.stringify(requestedConfig))))

                    if (currentSignature !== requestedSignature) {
                        await this.setState(({ reportConfig }) => ({
                            reportConfig: {
                                ...reportConfig,
                                ...requestedConfig,
                            },
                        }))

                        this.handleConfigChange()
                    }
                } catch {}
            }
        } else {
            this.sendSelectTypeStatus()
        }
    }

    protected updatePathConfig(config: any, fullPathUpdate: boolean = false): void {
        // tslint:disable
        let { columns, ...parsedConfig } = config
        // tslint:enable

        if (fullPathUpdate) {
            parsedConfig = {
                ...parsedConfig,
                columns,
            }
        }

        const currentConfigSig = btoa(unescape(encodeURIComponent(this.queryString.report as any)))
        const newConfigSig = btoa(unescape(encodeURIComponent(JSON.stringify(parsedConfig))))

        if (currentConfigSig !== newConfigSig) {
            const parsedUpdate = `?report=${JSON.stringify(parsedConfig)}`
            // this.injectedProps.history.push({ search: parsedUpdate });
        }
    }
}
