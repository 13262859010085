import React from 'react'
import './style/org-notification.scss'
import { OrgNotificationModel } from '../../models/notification/org-notification.model'
import moment from 'moment-timezone'
import { BASE_TIME_FORMAT, BASE_TIME_FORMAT_WITHOUT_TZ } from '../../constants'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { arrayUniqueObjects, titleCase } from '../../_utils/utils'
import { MultiDomainBadge } from '../../components/badges/multi-domain-badge'
import { NotificationImageBadge } from '../../components/badges/notification-image-badge'
import { NotificationSilentBadge } from '../../components/badges/notification-silent-badge'
import { NotificationStzBadge } from '../../components/badges/notification-stz-badge'
import { generateShortID } from '../../components/campaign-builder/helpers/uid'
import { Skeleton, Tag } from 'antd'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import OrgNotificationLoader from './helpers/org-notification-loader'
import { ILoadableDataState } from '../../hooks/use-loadable-data-ref-state'
import OrgLoader from './helpers/org-loader'
import { AccountDto } from '../../dtos/account.dto'
import { TabbedView } from '../../components/tabbed-view/tabbed-view'
import SimpleTabbedView from '../../components/simple-tabbed-view/simple-tabbed-view'
import OrgNotificationSummaryTab from './org-notification-summary-tab'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { flatMap } from '../../_utils/array'
import { NoTranslate } from '../../components/no-translate/no-translate'
import { determineChannelBadge } from '../../components/badges/_utils'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IHeaderProps {
    loader: React.MutableRefObject<ILoadableDataState<OrgNotificationModel | undefined>>
    org: AccountDto | undefined
}

const BadgesDisplay = ({ loader, org }: IHeaderProps) => {
    const appState = useService(AppState)
    const notification = loader.current.data
    const badges: any[] = []
    const build = notification?.getVariant()
    const content = build?.getContent().getDefaultContent()
    const expOpts = content?.getExperienceOptions()
    const delivery = build?.getDelivery()
    const viewHasNativePush = org?.isIOSEnabled(appState) || org?.isIOSEnabled(appState)

    if (build?.getChannels()) {
        badges.push(
            build
                ?.getChannels()
                .filter((val) => (!viewHasNativePush ? val === DeliveryChannel.WEB : true))
                .map((ch) => (
                    <span key={ch} className="option">
                        {determineChannelBadge(ch)}
                    </span>
                )),
        )
    }

    if (notification?.getIsMultiDomain()) {
        badges.push(
            <span key="badge-multi-domain" className="option">
                <MultiDomainBadge entityLabel="Notification" />
            </span>,
        )
    }

    if (!!content?.getImageUrl()) {
        badges.push(
            <span key="badge-image" className="option">
                <NotificationImageBadge />
            </span>,
        )
    }

    if (expOpts?.getIsSilent()) {
        badges.push(
            <span key="badge-silent" className="option">
                <NotificationSilentBadge />
            </span>,
        )
    }

    if (delivery?.isUsingStzStrategy) {
        badges.push(
            <span key="badge-stz" className="option">
                <NotificationStzBadge />
            </span>,
        )
    }

    if (badges.length > 0) badges.unshift(<span key={generateShortID()}>|</span>)

    return <>{badges}</>
}

const OrgNotificationDetailsHeader = ({ loader, org }: IHeaderProps) => {
    const appState = useService<AppState>(AppState)

    const notif = loader.current.data
    const build = notif?.getVariant()
    const content = build?.getContent().getDefaultContent()
    const expOpts = content?.getExperienceOptions()
    const delivery = build?.getDelivery()

    // delivery strategy
    const type = delivery?.getType()
    const window = delivery?.getWindow()

    let strategyStr: any = ''
    if (window === NotificationDeliveryWindow.INFORMED) {
        strategyStr = 'informed'
    } else if (type === NotificationDeliveryType.IMMEDIATE) {
        strategyStr = 'immediate'
    } else if (window === NotificationDeliveryWindow.STANDARD) {
        strategyStr = 'fixed'
    } else if (window === NotificationDeliveryWindow.TIMEZONE) {
        strategyStr = 'subscriber time zone'
    }

    // delivery dates
    const isSTZ = delivery?.isUsingStzStrategy ?? false
    const sendDateFormat = isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT

    const startDate = moment.tz(delivery?.getSendDate(), delivery?.getTimeZone() ?? org?.timezone)
    const expiryDate = startDate.clone().add(expOpts?.getTtlSeconds(), 'seconds')

    const flightDateStart = startDate.format(isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT)
    const flightDateEnd = expiryDate.format(sendDateFormat)

    // audience
    let domainsView: React.ReactNode
    let segmentsView: React.ReactNode = <Tag key="all-subs">All Subscribers</Tag>
    const audience = notif?.getVariant()?.getAudience()
    const currUserDomains = appState.currentUserDomains ?? []

    if (audience?.getDomainIds()) {
        const domainIds = audience.getDomainIds()!.map((id) => id.toString())
        const audienceDomains = currUserDomains.filter((d) => domainIds.includes(d.id.toString()))
        let domainTags: React.ReactNode[] = audienceDomains.map((domain) => (
            <Tag key={domain.id}>{domain.displayName}</Tag>
        ))

        domainsView = domainTags
    }

    if (audience?.getSegmentIds()) {
        const segments = arrayUniqueObjects(
            flatMap(notif!.getNotifications()!, (n) => n.includedSegments ?? []),
            'groupId',
        )
        segmentsView = segments.map((segment: any) => <Tag key={segment.id}>{segment.name}</Tag>)
    }

    return (
        <div className="details-header">
            <Skeleton
                title={false}
                paragraph={{ rows: 5 }}
                loading={loader.current.loading || !loader.current.data}
                active={true}
            >
                <div className="notif-title">
                    <h2>{content?.getTitle()}</h2>
                    <div className="append">
                        <div className="notif-id">
                            <span>
                                #{notif?.getId()}{' '}
                                <span>
                                    | Created by <NoTranslate>{notif?.getCreatedByUserName()}</NoTranslate>
                                </span>
                            </span>

                            <BadgesDisplay loader={loader} org={org} />
                        </div>

                        <div className="notif-dates">
                            <span>
                                <b>Delivery Date Range</b> {flightDateStart} through {flightDateEnd}
                            </span>
                        </div>

                        <div className="notif-strategy">
                            <span className="delivery-strategy">
                                <b>Delivery Strategy</b> {!!strategyStr && titleCase(strategyStr)}
                            </span>
                        </div>

                        <div className="notif-audience">
                            <div className="domains-section">
                                <div>
                                    <b>Domains</b>
                                </div>
                                <div>{domainsView}</div>
                            </div>
                        </div>

                        <div className="notif-audience">
                            <div className="audience-section">
                                <div>
                                    <b>Included Segments</b>
                                </div>
                                <div>{segmentsView}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Skeleton>
        </div>
    )
}

class OrgNotification extends TabbedView<any, any> {
    public render() {
        const orgId = getPathEntityId('organization')
        const notifId = getPathEntityId('notification')

        return (
            <div className="notification-details org-notification-details page">
                <OrgLoader orgId={orgId!}>
                    {(orgLoader) => (
                        <OrgNotificationLoader
                            orgId={orgId!}
                            notifId={notifId!.toString()}
                            stats={true}
                            includeCancelledChildren={true}
                        >
                            {(loader) => (
                                <>
                                    <OrgNotificationDetailsHeader loader={loader} org={orgLoader.current.data} />
                                    <SimpleTabbedView
                                        pathStyle="slash"
                                        animated={false}
                                        tabs={[
                                            {
                                                component: OrgNotificationSummaryTab,
                                                props: () => ({
                                                    notifLoader: loader,
                                                    orgLoader,
                                                }),
                                            },
                                        ]}
                                    />
                                </>
                            )}
                        </OrgNotificationLoader>
                    )}
                </OrgLoader>
            </div>
        )
    }
}

export default OrgNotification
