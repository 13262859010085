import React from 'react'
import classnames from 'classnames'

export type DropdownViewSelectOption<ValueType> = {
    readonly label: string
    readonly title: string
    readonly value: ValueType
}

export function selectOptionMatchesFilter<ValueType>(
    filterValue: string,
    option: DropdownViewSelectOption<ValueType>,
): boolean {
    return option.label.toLowerCase().includes(filterValue.toLowerCase())
}

export type DropdownViewProps<ValueType> = {
    readonly menu: React.ReactElement
    readonly options: ReadonlyArray<DropdownViewSelectOption<ValueType>>
    readonly selectedOptions: ReadonlyArray<ValueType>
    readonly onChange: (selectedOptions: ValueType[]) => any
    readonly filter?: string
    readonly selectAllOptionClassName?: string
}

export function DropdownViewWithSelectAll<ValueType>(props: DropdownViewProps<ValueType>) {
    const { menu, selectedOptions, filter, onChange } = props

    const options: ReadonlyArray<DropdownViewSelectOption<ValueType>> = menu.props.options ?? props.options

    if (!options.length) {
        // If no available options pre-filter return menu without "Select All"
        return menu
    }

    if (filter && !options.filter((opt) => selectOptionMatchesFilter(filter, opt)).length) {
        // If no available options post-filter return menu without "Select All"
        return menu
    }

    const optionValues = options.map((opt) => opt.value)

    const allAreSelected = selectedOptions.length === props.options.length
    const allFilteredAreSelected = optionValues.every((value) => selectedOptions.includes(value))

    // Select all label should be dependent on filtered/visible options
    const selectAllLabel = !allFilteredAreSelected ? 'Select All' : 'Deselect All'

    return (
        <>
            <div
                className={classnames(
                    'select-all-option ant-select-item ant-select-item-option',
                    props.selectAllOptionClassName,
                )}
                onClick={() =>
                    onChange(
                        allAreSelected && !filter
                            ? // all possible currently selected w/o filter: reset all selections
                              []
                            : allFilteredAreSelected
                            ? // filter is not empty: remove only the filtered options
                              selectedOptions.filter((val) => !optionValues.includes(val))
                            : // append next selections
                              Array.from(new Set([...selectedOptions, ...optionValues])),
                    )
                }
            >
                <div className="ant-select-item-option-content">{selectAllLabel}</div>
            </div>
            {menu}
        </>
    )
}
