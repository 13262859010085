import * as React from 'react'
import { Container, Singleton } from 'typescript-ioc/es5'
import { simpleNotification, titleCase } from '../_utils/utils'
import { AppService } from './app'
import { NotificationDto, SendNotificationRequestDto } from '../features/notifications'
import * as querystring from 'query-string'
import { AppState } from '../stores/app'
import { NotificationScheduleDto } from '../features/notifications/dtos/notification-schedule-dto'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { sendGAEvent } from '../components/ga-helper/ga-helper'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import IApiCallOptions from '../interfaces/api-call-options'
import { INotificationFieldSuggestions } from '../interfaces/notification-field-suggestions'
import aqe from '@pushly/aqe'
import { ApiVersion } from '../enums/api-version.enum'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'

const extractErrorMessage = (message: string) => {
    return message.replace(/(?:template\.channels\.web\.)([^\s]+)/gi, (...args: any[]) => {
        if (args.length > 1) {
            return titleCase(args[1])
        } else {
            return args[0]
        }
    })
}

@Singleton
export class NotificationService {
    public static async fetchStzAvailability(
        dateTime: string | Date,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<boolean>> {
        const appState = Container.get(AppState)
        const domainId = appState.currentDomain!.id

        const response: IServiceApiResponse<boolean> = {
            ok: false,
            data: false,
        }

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notifications/stz-availability?send_date=${dateTime}`,
                },
                opts?.cancellationKey,
            )
            const { data } = res.data

            response.ok = true
            response.data = data ?? false
        } catch (error) {
            if (isAxiosCancellation(error)) {
                response.cancelled = true
            } else {
                throw error
            }
        }

        return response
    }

    public static async fetchNotificationDuplicates(
        domainId: number,
        opts: any = {},
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<NotificationDto>> {
        let ok: boolean = false
        let notification: any = {}
        let meta: any

        const options = querystring.stringify(opts || {})

        const serviceUrl = `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/notifications/matches?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceUrl,
                },
                cancellationKey,
            )

            ok = true
            const { data, ...responseMeta } = req?.data
            meta = responseMeta

            notification = NotificationDto.parse(data[0])
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        return { ok, data: notification, meta }
    }

    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async analyzeLandingUrl(
        domainId: number,
        landingUrl: string,
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<INotificationFieldSuggestions>> {
        const response: IServiceApiResponse<INotificationFieldSuggestions> = {
            ok: false,
            data: {} as INotificationFieldSuggestions,
            meta: {},
            cancelled: false,
        }

        opts = opts ?? {}
        opts.query = { ...(opts.query ?? {}), url: landingUrl }
        const qs = querystring.stringify(opts.query)

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const res = await axiosFetch('get', {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notifications/analyze-landing-url?${qs}`,
            })
            const { data, ...meta } = res.data

            response.ok = true
            response.data = data
            response.meta = meta
        } catch (err) {
            response.cancelled = isAxiosCancellation(err)
            response.error = err
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return response
    }

    public async fetchNotificationsByDomainId(
        domainId: number,
        opts?: any,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<{ notifications: NotificationDto[]; meta: any; cancelled: boolean }> {
        if (showLoadingScreen) this.appService.setModuleLoading()
        const options = querystring.stringify(opts || {})

        let response: any = {
            notifications: [],
            meta: {},
            cancelled: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/notifications?${options}`,
                },
                cancellationKey,
            )
            const { data: notifs, ...data } = req.data
            const notifications = notifs.map((notification: any) => NotificationDto.parse(notification))

            response = {
                notifications,
                meta: data,
            }
        } catch (error) {
            response.cancelled = isAxiosCancellation(error)
        }

        if (showLoadingScreen) this.appService.unsetModuleLoading()

        return response
    }

    public async fetchNotificationById(
        domainId: number,
        notificationId: number,
        opts: ApiCallOptionsWithCustomErrorHandler = {},
    ): Promise<NotificationDto | undefined> {
        if (opts.showLoadingScreen) this.appService.setModuleLoading()
        const options = querystring.stringify(opts.query || {})

        let notification: NotificationDto | undefined
        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/${
                        opts.version ?? ApiVersion.NONE
                    }domains/${domainId}/notifications/${notificationId}?${options}`,
                },
                opts.cancellationKey,
            )

            notification = NotificationDto.parse(req.data.data)
        } catch (error) {
            if (opts.errorHandler) {
                const defaultHandler = () => handleResponseErrorMessage(error)
                opts.errorHandler(error, defaultHandler)
            }
        }

        if (opts.showLoadingScreen) this.appService.unsetModuleLoading()

        return notification
    }

    public async fetchTestsByDomainId(
        domainId: number,
        opts?: any,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any[]>> {
        if (showLoadingScreen) this.appService.setModuleLoading()
        const options = querystring.stringify(opts || {})

        let tests: any[] = []
        let meta: any = {}
        let ok = false

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/notification-tests?${options}`,
                },
                cancellationKey,
            )
            const { data: testArray, ...data } = req.data

            ok = true
            tests = testArray || []
            meta = data || {}
        } catch (error) {
            if (!isAxiosCancellation(error)) {
                throw error
            }
        }

        if (showLoadingScreen) this.appService.unsetModuleLoading()

        return { ok, data: tests, meta }
    }

    public async fetchTestById(
        domainId: number,
        testId: number,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<NotificationDto | void> {
        if (showLoadingScreen) this.appService.setModuleLoading()

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests/${testId}`,
                },
                cancellationKey,
            )
            const test = req.data.data
            if (test.notifications) {
                test.notifications = test.notifications.map(NotificationDto.parse)
            }
            if (test.schedules) {
                test.schedules = test.schedules.map(NotificationScheduleDto.parse)
            }

            if (showLoadingScreen) this.appService.unsetModuleLoading()
            return test
        } catch (error) {
            if (showLoadingScreen) this.appService.unsetModuleLoading()
        }
    }

    public async saveDraft(
        domainId: number,
        requestDto: SendNotificationRequestDto,
        options: IApiCallOptions = {},
    ): Promise<boolean> {
        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/notifications/drafts`,
                    data: requestDto,
                },
                options.cancellationKey,
            )

            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            if (error.response?.data) {
                const message = extractErrorMessage(error.response.data.message)
                const refUrl = error.response.data.reference_url

                simpleNotification(
                    'error',
                    !refUrl ? (
                        message
                    ) : (
                        <>
                            {message.replace(/\.$/, '')}. See our{' '}
                            <a href={refUrl} target="_blank">
                                documentation
                            </a>{' '}
                            for more information on this error.
                        </>
                    ),
                )
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async sendNotification(
        domainId: number,
        requestDto: SendNotificationRequestDto,
        options: IApiCallOptions = {},
    ): Promise<boolean> {
        this.appService.setModuleLoading()

        let messageDuration
        let messageStarter = 'Notification'
        if (requestDto.source === 'PREVIEW') {
            messageDuration = 7
            messageStarter = 'A notification preview'
        }

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/${
                        options.version ?? ApiVersion.NONE
                    }domains/${domainId}/notifications`,
                    data: requestDto,
                },
                options.cancellationKey,
            )

            try {
                const notification = NotificationDto.parse(req.data.data)
                this.sendGaNotificationEvent('create', notification)
            } catch {}

            let message: any = `${messageStarter} has been successfully scheduled.`
            if (requestDto.audience.personalPreview || requestDto.audience.teamPreview) {
                const teamPreview = requestDto.audience.teamPreview

                message = (
                    <>
                        <p>
                            {message} All registered {teamPreview ? 'team' : ''} devices should receive this preview
                            within the next 60 seconds.
                        </p>
                        <p>
                            If you do not receive the preview please contact your account manager.{' '}
                            {teamPreview && (
                                <>
                                    {' '}
                                    If any of your team members do not receive the preview they may need to log in to
                                    the platform and re-authenticate notification permissions via this page.
                                </>
                            )}
                        </p>
                    </>
                )
            }

            simpleNotification('success', message, messageDuration, 'sendNotification', true)
            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            if (error.response?.data) {
                const message = extractErrorMessage(error.response.data.message)
                const refUrl = error.response.data.reference_url

                simpleNotification(
                    'error',
                    !refUrl ? (
                        message
                    ) : (
                        <>
                            {message.replace(/\.$/, '')}. See our{' '}
                            <a href={refUrl} target="_blank">
                                documentation
                            </a>{' '}
                            for more information on this error.
                        </>
                    ),
                )
            } else {
                simpleNotification('error', `${messageStarter} could not be scheduled at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async updateNotification(
        domainId: number,
        notificationId: number,
        requestDto: SendNotificationRequestDto,
        options: IApiCallOptions = {},
    ): Promise<string | boolean> {
        this.appService.setModuleLoading()

        try {
            const res = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/${
                        options.version ?? ApiVersion.NONE
                    }domains/${domainId}/notifications/${notificationId}`,
                    data: requestDto,
                },
                options.cancellationKey,
            )
            const { data } = res.data

            simpleNotification('success', `Notification successfully updated.`)
            this.appService.unsetModuleLoading()

            return data.id
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `Notification could not be updated at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async updateNotificationDraft(
        domainId: number,
        notificationId: number,
        requestDto: SendNotificationRequestDto,
        options: IApiCallOptions = {},
    ): Promise<string | boolean> {
        this.appService.setModuleLoading()

        try {
            const res = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/${
                        options.version ?? ApiVersion.NONE
                    }domains/${domainId}/notifications/drafts/${notificationId}`,
                    data: requestDto,
                },
                options.cancellationKey,
            )
            const { data } = res.data

            simpleNotification('success', `Notification Draft successfully updated.`)
            this.appService.unsetModuleLoading()

            return data.id
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `Notification Draft could not be updated at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async archiveNotification(domainId: number, notificationId: number): Promise<boolean> {
        this.appService.setModuleLoading()

        try {
            await axiosFetch('delete', {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notifications/${notificationId}`,
            })

            simpleNotification('success', `Notification successfully archived.`)
            this.appService.unsetModuleLoading()

            return false
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `Notification could not be archived at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async createTest(
        domainId: number,
        abTestDto: any,
        treatmentDtos: SendNotificationRequestDto[],
        cancellationKey?: string,
        isDraft?: boolean,
    ): Promise<boolean> {
        this.appService.setModuleLoading()

        let messageDuration
        let messageStarter = 'Notification test'
        if (treatmentDtos[0].source === 'PREVIEW') {
            messageDuration = 7
            messageStarter = 'A notification test preview'
        }

        const url = isDraft
            ? `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests/drafts`
            : `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests`

        try {
            const pack = {
                url,
                data: {
                    ...abTestDto,
                    notifications: treatmentDtos,
                },
            }

            const req = await axiosFetch('post', pack, cancellationKey)

            try {
                const test = req.data.data
                this.sendGaMvNotificationEvent('create_multivariate', test)

                if (test.notifications) {
                    test.notifications = test.notifications.map(NotificationDto.parse)

                    test.notifications.forEach((notification: NotificationDto) => {
                        this.sendGaNotificationEvent('create', notification, true)
                    })
                }
            } catch {}

            let message: any = `${messageStarter} has been successfully scheduled.`
            if (treatmentDtos[0].audience.personalPreview || treatmentDtos[0].audience.teamPreview) {
                const teamPreview = treatmentDtos[0].audience.teamPreview

                message = (
                    <>
                        <p>
                            {message} All registered {teamPreview ? 'team' : ''} devices should receive this preview
                            within the next 60 seconds.
                        </p>
                        <p>
                            If you do not receive the preview please contact your account manager.{' '}
                            {teamPreview && (
                                <>
                                    {' '}
                                    If any of your team members do not receive the preview they may need to log in to
                                    the platform and re-authenticate notification permissions via this page.
                                </>
                            )}
                        </p>
                    </>
                )
            }

            simpleNotification('success', message, messageDuration, 'sendTest', true)
            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `${messageStarter} could not be scheduled at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async updateTest(
        domainId: number,
        testId: number,
        abTestDto: any,
        treatmentDtos: SendNotificationRequestDto[],
        cancellationKey?: string,
    ): Promise<boolean> {
        this.appService.setModuleLoading()

        try {
            const pack = {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests/${testId}`,
                data: {
                    ...abTestDto,
                    notifications: treatmentDtos,
                },
            }

            await axiosFetch('patch', pack, cancellationKey)

            simpleNotification('success', `Notification test successfully updated.`)
            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `Notification test could not be updated at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    public async updateTestDraft(
        domainId: number,
        testId: number,
        abTestDto: any,
        treatmentDtos: SendNotificationRequestDto[],
        cancellationKey?: string,
    ): Promise<boolean> {
        this.appService.setModuleLoading()

        try {
            const pack = {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests/drafts/${testId}`,
                data: {
                    ...abTestDto,
                    notifications: treatmentDtos,
                },
            }

            await axiosFetch('patch', pack, cancellationKey)

            simpleNotification('success', `Notification test successfully updated.`)
            this.appService.unsetModuleLoading()
            return true
        } catch (error) {
            if (error.response && error.response.data) {
                const message = extractErrorMessage(error.response.data.message)
                simpleNotification('error', message)
            } else {
                simpleNotification('error', `Notification test could not be updated at this time.`)
            }

            this.appService.unsetModuleLoading()
            return false
        }
    }

    // @todo - enable and replace calls to cancelSchedule in notification schedule service once management api is deployed
    public async cancelNotification(domainId: number, notificationId: number, cancellationKey?: string) {
        this.appService.setModuleLoading()

        let cancelled = false
        try {
            const pack = {
                url: `${aqe.defaults.publicApiDomain}/${ApiVersion.V4}domains/${domainId}/notifications/${notificationId}`,
                data: {
                    status: 'CANCELLED',
                },
            }

            await axiosFetch('patch', pack, cancellationKey)

            cancelled = true
            simpleNotification(
                'success',
                `Delivery of this notification has been cancelled. 
                Note: If this notification had recurrence or used user-timezone-based delivery 
                some users may have already received it.`.trim(),
            )
        } catch (error) {
            simpleNotification('error', `Notification could not be updated at this time.`)
        }

        this.appService.unsetModuleLoading()

        return cancelled
    }

    public async cancelTest(domainId: number, testId: number, cancellationKey?: string): Promise<boolean> {
        this.appService.setModuleLoading()

        let cancelled = false
        try {
            const pack = {
                url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-tests/${testId}`,
                data: {
                    status: 'CANCELLED',
                },
            }

            await axiosFetch('patch', pack, cancellationKey)

            cancelled = true
            simpleNotification('success', `Notification test successfully cancelled.`)
        } catch (error) {
            simpleNotification('error', `Notification test could not be updated at this time.`)
        }

        this.appService.unsetModuleLoading()

        return cancelled
    }

    private sendGaMvNotificationEvent(event: string, notification: NotificationDto): void {
        sendGAEvent(
            event,
            {
                event_category: 'notifications',
                event_label: notification.id,
                notification_id: notification.id,
            },
            this.appState,
        )
    }

    private sendGaNotificationEvent(
        event: string,
        notification: NotificationDto,
        isMultivariate: boolean = false,
    ): void {
        sendGAEvent(
            event,
            {
                event_category: 'notifications',
                event_label: notification.id,
                notification_id: notification.id,
                notification_template_id: notification.defaultTemplate.id,
                notification_included_segments: notification.audience.segmentIds,
                notification_excluded_segments: notification.audience.excludedSegmentIds,
                notification_keywords: notification.keywords,
                notification_is_multivariate: isMultivariate,
            },
            this.appState,
        )
    }
}
