import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { AccountService, NotificationService } from '../../services'
import { AppState } from '../../stores/app'
import { PageHeader } from '../../components/page-header/page-header'
import { tryParseInt } from '../../_utils/try-parse'
import NotificationBuilder from '../../components/notification-builder/notification-builder'
import NotificationBuilderAside from '../../components/notification-builder/elements/notification-builder-aside'
import NotificationBuilderReach from '../../components/notification-builder/elements/notification-builder-reach'
import NotificationBuilderPreview from '../../components/notification-builder/elements/notification-builder-preview'
import NotificationBuilderMain from '../../components/notification-builder/elements/notification-builder-main'
import NotificationVariantBuilder from '../../components/notification-builder/elements/notification-variant-builder'
import { useActiveDomainChangeEffect } from '../../hooks/use-active-domain-change-effect'
import { buildPreviewOptions, buildSubmittedNotificationPackages } from '../../components/notification-builder/helpers'
import OrgNotificationAudienceBuilder from '../../components/notification-builder/elements/org-notification-audience-builder'
import { useService } from '../../hooks/use-service'
import { NotificationBuilderLevel } from '../../components/notification-builder/enums'
import { AccountNotificationService } from '../../services/account-notification'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { AccountDto } from '../../dtos/account.dto'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import OrgNotificationLoader from './helpers/org-notification-loader'
import { convertOrgNotificationModelToNotificationDto } from './helpers/convert-org-notification-model-to-notification-dto'
import { PromiseableProp } from '../../_utils/promiseable-prop'
import { NotificationDto } from '../notifications/index'
import { IApiResponse } from '@pushly/aqe/lib/interfaces'
import { ApiVersion } from '../../enums/api-version.enum'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'
import { getAccountEnabledDeliveryChannels } from '../../_utils/account'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { useEffect, useState } from 'react'
import { ErrorPage } from '../../components/error-page/error-page'
import { DomainDto } from '../../dtos/domain'

const OrgNotificationSend = () => {
    useActiveDomainChangeEffect((_, appSvc) => {
        appSvc.routeWithin('org', '/notifications')
    })

    const { push: updateRoute } = useHistory()
    const qs = queryString.parse(location.search)
    const appState = useService(AppState)
    const acctService = useService(AccountService)
    const notifService = useService(NotificationService)
    const acctNotifService = useService(AccountNotificationService)

    const [, runIfMounted] = useMountedRef()
    const [accountData, setAccountData] = useLoadableDataState<AccountDto | undefined>({
        loading: true,
    })
    const [accountDomains, setAccountDomains] = useLoadableDataState<DomainDto[]>({
        loading: true,
    })

    const [canEditOrgNotif, setCanEditOrgNotif] = useState<boolean>(true)

    const orgId = getPathEntityId('organization')

    // run once on mounted
    useEffect(() => {
        const load = async () => {
            const [acctDto, accountDomainDtos] = await Promise.all([
                acctService.fetchById(orgId!, {}, true),
                acctService.fetchAccountDomains(orgId!, { pagination: 0 }, true),
            ])

            if (acctDto) {
                runIfMounted(() => {
                    setAccountData({
                        loading: false,
                        data: acctDto,
                    })
                })
            }

            if (accountDomainDtos) {
                runIfMounted(() => {
                    setAccountDomains({
                        loading: false,
                        data: accountDomainDtos,
                    })
                })
            }
        }

        load()
    }, [])

    const domain = appState.currentDomain!
    const account = accountData.data ?? ({} as any)

    const pathNotifId = getPathEntityId('notification') as string | undefined
    const qsTemplateId = tryParseInt(qs.notification_id?.toString() ?? '') || undefined
    const reqNotifId = pathNotifId ?? qsTemplateId

    const mode = pathNotifId ? 'edit' : 'create'
    const isCreateMode = !pathNotifId

    const goBackToNotifs = () => {
        updateRoute(`/organizations/${account.id}/notifications`)
    }

    const activeOrgChannels = getAccountEnabledDeliveryChannels(account)
    return (
        <>
            <PageHeader title={`${isCreateMode ? 'Create' : 'Edit'} Multi-Domain Notification`} />

            {accountData.loading || accountDomains.loading ? (
                <></>
            ) : (
                <OrgNotificationLoader orgId={orgId!} notifId={reqNotifId} includeCancelledChildren={false}>
                    {(loader) => {
                        const promiseableLoader: PromiseableProp<NotificationDto[] | undefined> = new Promise<
                            NotificationDto[] | undefined
                        >((res) => {
                            let loadedNotifs: NotificationDto[] | undefined
                            const interval = 10 // ms
                            const maxAttempts = (1000 * 60 * 3) / interval // 3m max load time
                            let attempts = 0

                            const unpackLoader = () => {
                                if (attempts >= maxAttempts) {
                                    res(undefined)
                                } else {
                                    attempts += 1

                                    if (!loader.current.loading) {
                                        if (!loader.current.data) {
                                            res(undefined)
                                        } else {
                                            const loadedNotifDto = convertOrgNotificationModelToNotificationDto(
                                                loader.current.data!,
                                            )
                                            if (loadedNotifDto) {
                                                const domainIdsWhereCanCreateNotif =
                                                    loadedNotifDto.audience.domainIds?.filter((domainId) =>
                                                        appState.abilityStore.can(
                                                            AbilityAction.CREATE,
                                                            asCaslSubject(SubjectEntity.NOTIFICATION, { domainId }),
                                                        ),
                                                    )

                                                if (
                                                    domainIdsWhereCanCreateNotif?.length !==
                                                    loadedNotifDto.audience.domainIds?.length
                                                ) {
                                                    if (qsTemplateId) {
                                                        // Filter domains during duplication and editing to only those editable by the current user
                                                        loadedNotifDto.audience.domainIds = domainIdsWhereCanCreateNotif
                                                    } else {
                                                        setCanEditOrgNotif(false)
                                                    }
                                                }

                                                if (loader.current.data?.getNotifications?.()) {
                                                    // if singular shared status - attach to working dto
                                                    const statuses = Array.from(
                                                        new Set(
                                                            loader.current.data.getNotifications().map((n) => n.status),
                                                        ),
                                                    )
                                                    if (statuses.length === 1) {
                                                        loadedNotifDto.status = statuses[0]
                                                    }
                                                }
                                                loadedNotifs = [loadedNotifDto]
                                            }
                                            res(loadedNotifs)
                                        }
                                    } else {
                                        setTimeout(() => unpackLoader(), interval)
                                    }
                                }
                            }

                            unpackLoader()
                        })

                        return !canEditOrgNotif ? (
                            <ErrorPage
                                errorCode={403}
                                label="You do not have sufficient permissions to edit this notification"
                                hideLogo={true}
                                hideBackgroundCode={true}
                            />
                        ) : (
                            <NotificationBuilder
                                level={NotificationBuilderLevel.ORG}
                                mode={mode}
                                domain={domain}
                                account={account}
                                notifications={promiseableLoader}
                                defaultSelectedNotifId={reqNotifId}
                            >
                                {(props) => {
                                    const {
                                        builder,
                                        dispatchChanges,
                                        validateSubmit,
                                        onAudienceChange,
                                        onChannelChange,
                                        variants,
                                        onVariantChange,
                                        variantManager,
                                    } = props
                                    const activeVariant = variants[builder.selectedVariantIdx]

                                    const audience = builder.variants[0]?.getAudience()
                                    const audienceDomainIds = (
                                        audience?.getDomainIds() ??
                                        accountDomains.data!.map((d) => d.id) ??
                                        []
                                    ).map((id) => id.toString())
                                    const audienceDomains = accountDomains.data!.filter((d) =>
                                        audienceDomainIds.includes(d.id.toString()),
                                    )

                                    const currDomain = audienceDomains[0] ?? domain
                                    const previewOptions = buildPreviewOptions(builder.channels)

                                    return (
                                        <>
                                            <NotificationBuilderAside>
                                                <NotificationBuilderReach
                                                    domain={audienceDomains}
                                                    account={account}
                                                    builder={builder}
                                                    setBuilder={dispatchChanges}
                                                    hideVariantReach={true}
                                                    hideDistributionManager={true}
                                                />
                                                <NotificationBuilderPreview
                                                    level="org"
                                                    levelId={orgId!}
                                                    loading={builder.loading}
                                                    domain={currDomain}
                                                    source={activeVariant}
                                                    options={previewOptions}
                                                    onPreviewRequest={async (type) => {
                                                        const valid = await validateSubmit(
                                                            builder,
                                                            currDomain,
                                                            appState.flags,
                                                            true,
                                                        )

                                                        if (valid) {
                                                            const builds = buildSubmittedNotificationPackages(
                                                                currDomain,
                                                                builder,
                                                                appState.flags,
                                                                type,
                                                            )
                                                            const previewBuild = builds[builder.selectedVariantIdx]

                                                            await notifService.sendNotification(
                                                                currDomain.id,
                                                                previewBuild,
                                                                {
                                                                    cancellationKey: `notif-send-preview`,
                                                                    version: ApiVersion.V4,
                                                                },
                                                            )
                                                        }
                                                    }}
                                                />
                                            </NotificationBuilderAside>

                                            <NotificationBuilderMain>
                                                {activeOrgChannels.length > 1 && (
                                                    <DeliveryChannelSelector
                                                        type="multiple"
                                                        loading={builder.loading}
                                                        value={builder.channels}
                                                        onChange={onChannelChange}
                                                        visibleChannels={activeOrgChannels}
                                                    />
                                                )}
                                                <OrgNotificationAudienceBuilder
                                                    loading={builder.loading}
                                                    disabled={!isCreateMode}
                                                    segmentSelectDisabled={activeVariant.getDisplayMeta().notEditable}
                                                    account={account}
                                                    setBuilder={dispatchChanges}
                                                    value={activeVariant.getAudience()}
                                                    onChange={onAudienceChange}
                                                />

                                                <NotificationVariantBuilder
                                                    key={activeVariant.getId()}
                                                    id={builder.selectedVariantIdx}
                                                    domain={currDomain}
                                                    account={account}
                                                    builder={builder}
                                                    variant={activeVariant}
                                                    manager={variantManager}
                                                    hideImageMacroToggle={true}
                                                    keywordOptions={account.keywords?.map((kw) => ({
                                                        value: kw,
                                                        label: kw,
                                                    }))}
                                                    onChange={onVariantChange}
                                                    onCancel={goBackToNotifs}
                                                    onSubmit={async () => {
                                                        const valid = await validateSubmit(
                                                            builder,
                                                            currDomain,
                                                            appState.flags,
                                                        )

                                                        if (valid) {
                                                            const builds = buildSubmittedNotificationPackages(
                                                                currDomain,
                                                                builder,
                                                                appState.flags,
                                                            )

                                                            let res: IApiResponse<any>
                                                            if (isCreateMode) {
                                                                res = await acctNotifService.create(
                                                                    account.id,
                                                                    builds[0],
                                                                    {
                                                                        showLoadingScreen: true,
                                                                        cancellationKey: `notif-send`,
                                                                        version: ApiVersion.V4,
                                                                    },
                                                                )
                                                            } else {
                                                                res = await acctNotifService.update(
                                                                    loader.current.data!,
                                                                    builds[0],
                                                                    {
                                                                        showLoadingScreen: true,
                                                                        cancellationKey: `notif-update`,
                                                                        version: ApiVersion.V4,
                                                                    },
                                                                )
                                                            }

                                                            if (res.ok) {
                                                                goBackToNotifs()
                                                            }
                                                        }
                                                    }}
                                                />
                                            </NotificationBuilderMain>
                                        </>
                                    )
                                }}
                            </NotificationBuilder>
                        )
                    }}
                </OrgNotificationLoader>
            )}
        </>
    )
}

export default OrgNotificationSend
