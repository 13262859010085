import React from 'react'
import './notification-list.scss'
import { Container } from 'typescript-ioc/es5'
import classnames from 'classnames'
import * as clone from 'clone'
import * as moment from 'moment-timezone'
import * as deepEqual from 'react-fast-compare'
import { Well } from '@pushly/aqe/lib/components'
import { Drawer, Modal, Table, Tooltip } from 'antd'
import NotificationListHeader from './notification-list-header'
import { INotificationTableData, NotificationListState } from './interfaces'
import { AppState } from '../../stores/app'
import { AccountService, AppService, DomainService, NotificationService } from '../../services'
import { NotificationScheduleService } from '../../services/notification-schedule'
import { TablePaginationConfig } from 'antd/lib/table'
import { InsightsService } from '../../services/insights'
import { Key, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import {
    BASE_DATE_FORMAT,
    BASE_TIME_ONLY_FORMAT,
    BASE_TIME_ONLY_FORMAT_WITHOUT_TZ,
    FEAT_NOTIF_SOURCE_FILTERING,
    TZ_PLATFORM_DEFAULT,
} from '../../constants'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { ClicksColumnView } from '../clicks-column-view/clicks-column-view'
import { ImpressionsColumnView } from '../impressions-column-view/impressions-column-view'
import { CtrColumnView } from '../ctr-column-view/ctr-column-view'
import { NotificationListContext } from './notification-list-context'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { NotificationStatusBadge } from '../badges/notification-status-badge'
import { NotificationAbWinnerBadge } from '../badges/notification-ab-winner-badge'
import { AudienceBadge } from '../badges/audience-badge'
import { MultiDomainBadge } from '../badges/multi-domain-badge'
import { NotificationImageBadge } from '../badges/notification-image-badge'
import { NotificationSilentBadge } from '../badges/notification-silent-badge'
import { NotificationStzBadge } from '../badges/notification-stz-badge'
import { NotificationAbBadge } from '../badges/notification-ab-badge'
import { NotificationHoldOutBadge } from '../badges/notification-hold-out-badge'
import { ExclamationCircleFilled, FormOutlined, LoadingOutlined, WifiOutlined } from '@ant-design/icons'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { NotificationSource } from '../../enums/notification-source'
import { HOLD_OUT_COMPUTED_STATUS, StatusType } from '../../enums/status-type'
import { stripUndefined } from '../../_utils/strip-undefined'
import { AsyncButton } from '../async-button/async-button.component'
import { arrayContains, arrayUnique, convertCase, preventAll } from '../../_utils/utils'
import { NotificationListTypes } from './enums'
import { RefreshTimeout } from '../../enums/refresh-timeout'
import { tryParseInt } from '../../_utils/try-parse'
import { getQueryStringParam } from '../../_utils/get-query-string'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { AdditionalDetailsDataForm } from '../../features/notifications/notification-list/additional-details-data-form'
import { onDomainIdChange } from '../../_utils/domain'
import { onAccountIdChange } from '../../_utils/account'
import { AccountNotificationService } from '../../services/account-notification'
import { OrgNotificationModel } from '../../models/notification/org-notification.model'
import OrgNotificationAudienceBadge from '../badges/org-notification-audience-badge'
import { arrayUnqiue, flatMap } from '../../_utils/array'
import OrgNotificationNotesDataForm from '../org-notification-notes-data-form/org-notification-notes-data-form'
import { parseNotificationVariantFromApiResponse } from '../../features/organizations/helpers/parse-org-notification-model'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { getTablePaginationConfig } from '../segment-list/helpers'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'
import { WebBadge } from '../badges/web-badge'
import { NativeIosBadge } from '../badges/native-ios-badge'
import { NativeAndroidBadge } from '../badges/native-android-badge'
import { ApiVersion } from '../../enums/api-version.enum'
import { NoTranslate } from '../no-translate/no-translate'
import { addVisibilityAwareIntervalRunner, LastRunTimestampUpdater } from '../../_utils/visibility-api'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { RouteComponentProps } from 'react-router-dom'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { NotificationAudienceType } from '@pushly/models/lib/enums/notification-audience-type'
import { AccessRoleId } from '../../enums/access-role.enum'

type FilterKey = keyof NotificationListState['filters']
type IdType = number | string

// bug fix type for typescript < 4.3 inferrence
type NotificationData = OrgNotificationModel | NotificationScheduleDto
type TableData = INotificationTableData<OrgNotificationModel> | INotificationTableData<NotificationScheduleDto>
type IterableTableData = (
    | INotificationTableData<OrgNotificationModel>
    | INotificationTableData<NotificationScheduleDto>
)[]

function extractPrimaryIdFromData(data: NotificationData) {
    return data instanceof OrgNotificationModel ? data.getId()! : data.notificationId
}

function extractDeliverySpecFromData(data: NotificationData) {
    return data instanceof OrgNotificationModel
        ? convertCase(data.getDeliverySpec(), 'camel')
        : convertCase(data.deliverySpec, 'camel')
}

function extractCreatedAtFromData(data: NotificationData) {
    return data instanceof OrgNotificationModel ? data.getCreatedAt() : data.createdAt
}

function extractStatusNameFromData(data: NotificationData) {
    return data instanceof OrgNotificationModel ? data.getComputedStatus() : data.status
}

function extractAbTestFromData(data: NotificationData) {
    let abTest
    if (data instanceof OrgNotificationModel) {
        const childAbTest = data.getNotifications().find((n) => n.abTestId)
        if (!data.getIsMultiDomain() && childAbTest) {
            abTest = {
                id: childAbTest.abTestId,
                name: childAbTest.abTestName,
                weight: data.getVariant()?.getWeight?.() ?? 100,
                winner: !!childAbTest.abTestWinnerOriginId,
                winnerOriginId: childAbTest.abTestWinnerOriginId,
            }
        }
    } else {
        abTest = data.abTest
    }

    return abTest
}

function isHoldOutTest(data: NotificationData) {
    return (data as NotificationScheduleDto).deliverySpec.window === NotificationDeliveryWindow.HOLD_OUT
}

function extractChannelsFromData(data: NotificationData) {
    let channels
    if (data instanceof OrgNotificationModel) {
        channels = data.getVariant()?.getChannels()
    } else {
        channels = data.channels ?? data.defaultTemplate.channels
    }
    return channels
}

const DEFAULT_SOURCE_FILTER = [NotificationSource.MANUAL]

const DEFAULT_STATUS_FILTER = [
    StatusType.COMPLETED.name,
    StatusType.SCHEDULED.name,
    StatusType.DELIVERING.name,
    // HOLD_OUT status is derived from the schedule's delivery spec window and not a Status ID in DB
    HOLD_OUT_COMPUTED_STATUS,
    StatusType.CANCELLED.name,
    StatusType.FAILED.name,
]

const REFRESH_QS_PARAM = '_r'
const REFRESH_OPTS_QS_PARAM = '_ropts'

interface IOrgNotificationListProps {
    level: 'org'
    orgId: number
}

interface IDomainNotificationListProps {
    level: 'domain'
    domainId: number
}

type NotificationListProps = Partial<RouteComponentProps<any>> &
    (IOrgNotificationListProps | IDomainNotificationListProps) & {
        title?: string
        filterSize?: SizeType
        filtersAddonBefore?: React.ReactNode
        filtersAddonAfter?: React.ReactNode
        pageSize?: number
        hidePagination?: boolean
        hideRowActions?: boolean
        hideRefresh?: boolean
        hideAutoRefreshOptions?: boolean
        autoRefreshDisabled?: boolean
        hideFilters?: FilterKey | FilterKey[] | boolean
        filtersDisabled?: FilterKey | FilterKey[] | boolean
        defaultFilters?: {
            source?: NotificationSource[]
            status?: string[]
            search?: string
            showAllNotifications?: boolean
        }
        listType: NotificationListTypes
    }

type State = NotificationListState & {
    dataSourcePreviousQuery?: any
    previousRowIds?: (string | number)[]
    showAddtDetailsDrawer: boolean
    addtDetailsDrawerNotifId?: number
    paginationLinks?: IApiResponsePaginationLinks
    showNotificationArchiveDialog: boolean
    selectedNotification: OrgNotificationModel | NotificationScheduleDto | null
}

class NotificationList extends React.Component<NotificationListProps, State> {
    protected readonly appState: AppState
    protected readonly appSvc: AppService
    protected readonly orgSvc: AccountService
    protected readonly domainSvc: DomainService
    protected readonly orgNotificationSvc: AccountNotificationService
    protected readonly notificationSvc: NotificationService
    protected readonly scheduleSvc: NotificationScheduleService
    protected readonly insightsSvc: InsightsService

    protected unmounting: boolean = false
    protected refreshTimer: any
    protected updateAutoRefreshLastRunTimestamp?: LastRunTimestampUpdater
    protected disposeObservers: (() => void)[] = []

    public constructor(props: NotificationListProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appSvc = Container.get(AppService)
        this.orgSvc = Container.get(AccountService)
        this.domainSvc = Container.get(DomainService)
        this.orgNotificationSvc = Container.get(AccountNotificationService)
        this.notificationSvc = Container.get(NotificationService)
        this.scheduleSvc = Container.get(NotificationScheduleService)
        this.insightsSvc = Container.get(InsightsService)

        this.state = this.applyDefaults({
            level: this.props.level,
            levelDependenciesLoaded: false,
            org: null!, // loaded on mount
            domain: null!, // loaded on mount

            dataSourceLoaded: false,
            dataSource: [],

            refreshing: false,
            refreshEnabled: !this.props.autoRefreshDisabled,
            refreshTimeout: RefreshTimeout.FIFTEEN_SECONDS,

            paginationConfig: getTablePaginationConfig({
                pageSize: this.props.pageSize ?? 15,
            }),

            sortedInfo: {
                columnKey: 'scheduleDate',
                order: 'descend',
            },

            selectedIds: [],
            filters: {
                search: this.props.defaultFilters?.search,
                status: this.props.defaultFilters?.status ?? [
                    StatusType.CANCELLED.name,
                    StatusType.COMPLETED.name,
                    StatusType.DELIVERING.name,
                    // HOLD_OUT status is derived from the schedule's delivery spec window and not a Status ID in DB
                    HOLD_OUT_COMPUTED_STATUS,
                    StatusType.FAILED.name,
                    StatusType.SCHEDULED.name,
                ],
                showAllNotifications: this.props.defaultFilters?.showAllNotifications ?? this.props.level !== 'org',
            },

            showAddtDetailsDrawer: false,
            showNotificationArchiveDialog: false,
            selectedNotification: null,
        })
    }

    public async componentDidMount() {
        let entityIdChangeHandler = this.props.level === 'domain' ? onDomainIdChange : onAccountIdChange
        this.disposeObservers.push(
            entityIdChangeHandler(this.appState, async () => {
                await this.fetchOrganizationalDependencies()
                this.resetList()
            }),
        )

        await this.fetchOrganizationalDependencies()
        this.fetchNotifications({ listType: this.props.listType })

        const [cleaner, updateTS] = addVisibilityAwareIntervalRunner(
            'notifications-list',
            async () => this.handleAutoRefresh(),
            this.state.refreshTimeout,
            true,
        )

        this.updateAutoRefreshLastRunTimestamp = updateTS
        this.disposeObservers.push(cleaner)
    }

    public componentWillUnmount() {
        this.unmounting = true
        this.disposeObservers.forEach((fn) => fn())
    }

    public render() {
        const { levelDependenciesLoaded, dataSourceLoaded, refreshing, showNotificationArchiveDialog } = this.state

        const { listType } = this.props

        const showTableLoadingAnimation = !levelDependenciesLoaded || (!dataSourceLoaded && !refreshing)

        // parse hideFilters prop for downstream
        let filtersHidden: FilterKey[] = []
        if (this.props.hideFilters === true) {
            filtersHidden = ['source', 'status', 'search', 'showAllNotifications']
        } else if (Array.isArray(this.props.hideFilters)) {
            filtersHidden = this.props.hideFilters
        } else if (this.props.hideFilters) {
            filtersHidden = [this.props.hideFilters]
        }

        const forceHideSourceFilter = this.props.level === 'org' || !this.currentDomainHasSourceFiltering(this.state)
        if (!filtersHidden.includes('source') && forceHideSourceFilter) {
            filtersHidden.push('source')
        }

        const forceHideShowAllFilter = this.props.level === 'domain'
        if (!filtersHidden.includes('showAllNotifications') && forceHideShowAllFilter) {
            filtersHidden.push('showAllNotifications')
        }

        // expose hidden options
        let showRefreshOptions: boolean | undefined
        const showRefreshOptsParams = getQueryStringParam(REFRESH_OPTS_QS_PARAM) ?? false
        if (
            showRefreshOptsParams !== undefined &&
            (showRefreshOptsParams === 'true' || showRefreshOptsParams === '1')
        ) {
            showRefreshOptions = true
        }

        const canShowActions =
            listType === NotificationListTypes.NOTIFICATION ||
            (listType === NotificationListTypes.DRAFT && this.canMutate())

        return (
            <NotificationListContext.Provider
                value={{
                    ...this.state,
                    onSearchClick: this.handleSearchClick,
                    onFilterChange: this.handleFilterChange,
                    onRefreshClick: this.handleRefreshClick,
                    // not currently utilizing refresh configs
                    onRefreshEnabledChange: () => {
                        /* noop */
                    },
                    onRefreshTimeoutChange: () => {
                        /* noop */
                    },
                }}
            >
                <Well
                    className={classnames('notification-list', 'table-well', 'nested')}
                    header={
                        <NotificationListHeader
                            title={this.props.title}
                            filterSize={this.props.filterSize}
                            filtersAddonBefore={this.props.filtersAddonBefore}
                            filtersAddonAfter={this.props.filtersAddonAfter}
                            hideFilters={filtersHidden}
                            hideRefresh={this.props.hideRefresh}
                            hideAutoRefreshOptions={
                                showRefreshOptions === undefined
                                    ? this.props.hideAutoRefreshOptions
                                    : !showRefreshOptions
                            }
                            filtersDisabled={this.props.filtersDisabled}
                            listType={listType}
                        />
                    }
                    hideFooter={true}
                >
                    <div className={classnames('notification-list-content')}>
                        <Table
                            className={classnames('notification-list-table', {
                                'hide-title': !this.props.title,
                                'hide-pagination': this.props.hidePagination,
                                'hide-actions': !this.currentUserHasWriteAccess,
                            })}
                            rowClassName="notification-list-row"
                            size="small"
                            dataSource={this.state.dataSource}
                            rowKey={(r) => extractPrimaryIdFromData(r.data)}
                            pagination={false}
                            footer={(currentTableData) => (
                                <SwSimpleLinkPagination
                                    currentTableData={currentTableData}
                                    links={this.state.paginationLinks}
                                    onPrev={async (_ev, _link, config) => {
                                        await this.setCurrentPage(config.current)
                                        this.fetchNotifications({ listType })
                                    }}
                                    onNext={async (_ev, _link, config) => {
                                        await this.setCurrentPage(config.current)
                                        this.fetchNotifications({ listType })
                                    }}
                                />
                            )}
                            onChange={this.handleTableChange}
                            locale={
                                listType === NotificationListTypes.DRAFT
                                    ? {
                                          emptyText: (
                                              <AntdTableEmptyPlaceholder text="No Drafts" icon={<FormOutlined />} />
                                          ),
                                      }
                                    : {
                                          emptyText: (
                                              <AntdTableEmptyPlaceholder
                                                  text="No Notifications"
                                                  icon={<WifiOutlined />}
                                              />
                                          ),
                                      }
                            }
                            loading={
                                showTableLoadingAnimation && {
                                    indicator: <LoadingOutlined spin={true} />,
                                }
                            }
                        >
                            <Table.Column
                                key="id"
                                className="notification"
                                dataIndex={['data', 'id']}
                                title="Notification"
                                render={this.renderNotificationDisplay}
                            />

                            <Table.Column
                                key="schedule-date"
                                sorter={({ data: a }: TableData, { data: b }: TableData) => {
                                    const aSpec = extractDeliverySpecFromData(a)
                                    const bSpec = extractDeliverySpecFromData(b)

                                    const aDate = moment(aSpec.sendDateUtc || extractCreatedAtFromData(a))
                                    const bDate = moment(bSpec.sendDateUtc || extractCreatedAtFromData(b))

                                    return aDate > bDate ? 1 : aDate < bDate ? -1 : 0
                                }}
                                defaultSortOrder="descend"
                                dataIndex={['data', 'deliverySpec']}
                                className="schedule-date"
                                title="Date"
                                align="center"
                                render={this.renderSendDate}
                            />

                            {listType === NotificationListTypes.NOTIFICATION && (
                                <Table.Column
                                    key="impressions"
                                    className="total-impressions"
                                    dataIndex={['stats', 'impressions']}
                                    title="Impressions"
                                    align="center"
                                    render={(imps: number, { data }: TableData) => {
                                        return (
                                            <ImpressionsColumnView
                                                impressions={imps}
                                                status={extractStatusNameFromData(data)}
                                            />
                                        )
                                    }}
                                />
                            )}

                            {listType === NotificationListTypes.NOTIFICATION && (
                                <Table.Column
                                    key="clicks"
                                    dataIndex={['stats', 'clicks']}
                                    className="total-clicks"
                                    title="Clicks"
                                    align="center"
                                    render={(clicks: number, { data }: TableData) => {
                                        return (
                                            <ClicksColumnView
                                                clicks={clicks}
                                                status={extractStatusNameFromData(data)}
                                            />
                                        )
                                    }}
                                />
                            )}

                            {listType === NotificationListTypes.NOTIFICATION && (
                                <Table.Column
                                    key="ctr"
                                    className="total-clicks"
                                    title="CTR"
                                    align="center"
                                    render={(_, row: TableData) => {
                                        return (
                                            <CtrColumnView
                                                impressions={row.stats.impressions}
                                                clicks={row.stats.clicks}
                                                status={extractStatusNameFromData(row.data)}
                                            />
                                        )
                                    }}
                                />
                            )}

                            {canShowActions && (
                                <Table.Column
                                    key="actions"
                                    className="actions"
                                    title="Actions"
                                    align="right"
                                    render={this.renderRowActions}
                                />
                            )}
                        </Table>
                    </div>

                    <Drawer
                        key="details-drawer"
                        className="notification-addt-details-drawer"
                        title="Edit Notification Details"
                        placement="right"
                        closable={true}
                        onClose={this.handleAddtDetailsDrawerClose}
                        visible={this.state.showAddtDetailsDrawer}
                    >
                        {this.state.showAddtDetailsDrawer &&
                            (this.props.level === 'domain' ? (
                                <AdditionalDetailsDataForm
                                    key={this.state.addtDetailsDrawerNotifId}
                                    getEntity={this.getCurrentDrawerData}
                                    onSubmit={this.handleAddtDetailsDrawerClose}
                                    onCancel={this.handleAddtDetailsDrawerClose}
                                />
                            ) : (
                                <OrgNotificationNotesDataForm
                                    key={this.state.addtDetailsDrawerNotifId}
                                    getEntity={this.getCurrentDrawerData}
                                    onSubmit={this.handleAddtDetailsDrawerClose}
                                    onCancel={this.handleAddtDetailsDrawerClose}
                                />
                            ))}
                    </Drawer>

                    <Modal
                        visible={showNotificationArchiveDialog}
                        className="notification-list-archive-dialog"
                        title="Confirm Notification Archival"
                        okText="Archive"
                        onOk={this.handleNotificationArchive}
                        cancelText="Cancel"
                        onCancel={this.handleDismissNotificationArchiveDialog}
                    >
                        <div>
                            {this.props.listType === NotificationListTypes.NOTIFICATION ? (
                                <p>
                                    This notification will no longer be shown on the Notification List page. If this
                                    notification has accrued any stats it will still display in Insights reports.
                                </p>
                            ) : (
                                <p>This notification draft will no longer be shown on the Notification Drafts page.</p>
                            )}
                        </div>
                    </Modal>
                </Well>
            </NotificationListContext.Provider>
        )
    }

    protected get currentUserHasWriteAccess(): boolean {
        let userHasWriteAccess

        if (this.props.level === 'domain') {
            // Can user write to segments at the domain level?
            userHasWriteAccess = this.appState.abilityStore.can(
                AbilityAction.UPDATE,
                this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION),
            )
        } else {
            const orgId = this.props.orgId
            const accessibleDomains =
                this.appState.currentUserDomains?.filter((d) => d.accountId?.toString() === orgId.toString()) ?? []

            // Can user write to segments at the org level?
            userHasWriteAccess =
                this.appState.abilityStore.can(
                    AbilityAction.UPDATE,
                    this.appState.abilityStore.getOrgOwnedIdentityFor(SubjectEntity.ORG_NOTIFICATION),
                ) && accessibleDomains.length > 1
        }

        return userHasWriteAccess
    }

    protected renderNotificationDisplay = (id: string, { data }: TableData) => {
        let canEdit = this.canMutate()
        if (this.props.level === 'org') {
            canEdit = this.canEditOrgSegment(data as OrgNotificationModel)
        }

        let isMultiDomain = false
        let containsImage = false
        let isSilent = false
        let additionalDetails
        let title
        let body

        const { listType } = this.props

        if (data instanceof OrgNotificationModel) {
            isMultiDomain = data.getIsMultiDomain()
            additionalDetails = data.getNotes()
            title = ' '
            body = ' '

            const notifData = data.getVariant()
            if (notifData) {
                containsImage = !!notifData.getContent().getDefaultContent().getImageUrl()
                isSilent = notifData.getContent().getDefaultContent().getExperienceOptions().getIsSilent() ?? false
                title = notifData.getContent().getDefaultContent().getTitle()
                body = notifData.getContent().getDefaultContent().getBody() ?? body
            }
        } else {
            isMultiDomain = data.notificationGroup?.isMultiDomain ?? false
            additionalDetails = data.additionalDetails
            containsImage = !!data.defaultTemplate.imageUrl
            isSilent = data.defaultTemplate.isSilent ?? false
            title = data.defaultTemplate.title
            body = data.defaultTemplate.body
        }

        const abTest = extractAbTestFromData(data)
        const deliverySpec = extractDeliverySpecFromData(data)
        const isStz = deliverySpec.window === NotificationDeliveryWindow.TIMEZONE

        const channels = extractChannelsFromData(data)

        const isDomainLevel = this.props.level === 'domain'
        const showMultiDomainBadge =
            isMultiDomain &&
            (isDomainLevel || this.state.filters.showAllNotifications) &&
            this.appState.abilityStore.can(
                AbilityAction.READ,
                this.appState.abilityStore.getOrgOwnedIdentityFor(SubjectEntity.ORG_NOTIFICATION),
            )

        return (
            <TableRowEntityDisplay
                title={
                    <NoTranslate>
                        {listType === NotificationListTypes.DRAFT ? (
                            <a className="notification-draft-name">{title}</a>
                        ) : canEdit ? (
                            <a href={this.buildDetailsUrl(data)}>{title}</a>
                        ) : (
                            title
                        )}
                    </NoTranslate>
                }
                description={<NoTranslate>{body}</NoTranslate>}
                status={
                    <NotificationStatusBadge
                        status={
                            listType === NotificationListTypes.NOTIFICATION
                                ? extractStatusNameFromData(data)
                                : StatusType.DRAFT.name
                        }
                        expanded={true}
                        holdOut={isHoldOutTest(data)}
                    />
                }
                test={
                    abTest && (
                        <>
                            {abTest.winner && <NotificationAbWinnerBadge />}
                            <span className="ab-name">
                                {listType === NotificationListTypes.DRAFT ? (
                                    <a className="notification-draft-name">{abTest.name}</a>
                                ) : (
                                    <a href={this.buildTestDetailsUrl(data)}>{abTest.name}</a>
                                )}
                            </span>
                        </>
                    )
                }
                audience={
                    <span className="audience-type">
                        {data instanceof OrgNotificationModel ? (
                            <OrgNotificationAudienceBadge source={data} />
                        ) : (
                            <AudienceBadge source={data} />
                        )}
                    </span>
                }
                badges={
                    <>
                        {showMultiDomainBadge && <MultiDomainBadge entityLabel="Notification" />}
                        {containsImage && <NotificationImageBadge />}
                        {isSilent && <NotificationSilentBadge />}
                        {isStz && <NotificationStzBadge />}
                        {abTest && <NotificationAbBadge />}
                        {isHoldOutTest(data) && <NotificationHoldOutBadge />}
                        {channels?.includes(DeliveryChannel.WEB) && <WebBadge />}
                        {channels?.includes(DeliveryChannel.NATIVE_IOS) && <NativeIosBadge />}
                        {channels?.includes(DeliveryChannel.NATIVE_ANDROID) && <NativeAndroidBadge />}

                        {/* Don't add anything below this line */}
                        {!!additionalDetails ? (
                            <span>
                                <Tooltip title={additionalDetails}>
                                    <ExclamationCircleFilled className="additional-details" />
                                </Tooltip>
                            </span>
                        ) : (
                            <></>
                        )}
                    </>
                }
            />
        )
    }

    protected renderSendDate = (_: any, { data }: TableData) => {
        const { listType } = this.props
        const isDraft = listType === NotificationListTypes.DRAFT

        const deliverySpec = extractDeliverySpecFromData(data)
        const isStz = deliverySpec.window === NotificationDeliveryWindow.TIMEZONE
        const isInf = deliverySpec.window === NotificationDeliveryWindow.INFORMED
        const isStd = deliverySpec.window === NotificationDeliveryWindow.STANDARD
        const isHoldOut = deliverySpec.window === NotificationDeliveryWindow.HOLD_OUT
        const isImmediate = deliverySpec.type === NotificationDeliveryType.IMMEDIATE
        const isImmediateDraft = isDraft && isImmediate

        let displayTz = TZ_PLATFORM_DEFAULT
        if (this.state.level === 'domain' && this.state.domain?.timezone) {
            displayTz = this.state.domain.timezone
        } else if (this.state.level === 'org' && this.state.org?.timezone) {
            displayTz = this.state.org.timezone ?? displayTz
        }

        const sendDateTz = deliverySpec.timezone ?? displayTz
        const sendDateUtc = deliverySpec.sendDateUtc ?? (data as any).sendDateUtc
        const sendDate = moment.tz(sendDateUtc, sendDateTz)

        // stz sends should keep original send/time
        if (!isStz) {
            sendDate.tz(displayTz)
        }

        return (
            <div className="composite-stack">
                {isImmediateDraft ? <span>Immediate</span> : <span>{sendDate.format(BASE_DATE_FORMAT)}</span>}
                {!isImmediateDraft && (
                    <span className="lower">
                        {isStz && (
                            <span>
                                {sendDate.format(BASE_TIME_ONLY_FORMAT_WITHOUT_TZ)}
                                <span className="stz-label">
                                    <Tooltip title="Subscriber's Time Zone">STZ</Tooltip>
                                </span>
                            </span>
                        )}
                        {isInf && (
                            <span>
                                {sendDate.format(BASE_TIME_ONLY_FORMAT_WITHOUT_TZ)}
                                <span className="stz-label">
                                    <Tooltip title="Informed Delivery">INF</Tooltip>
                                </span>
                            </span>
                        )}
                        {(isStd || isHoldOut) && sendDate.format(BASE_TIME_ONLY_FORMAT)}
                    </span>
                )}
            </div>
        )
    }

    protected renderRowActions = (
        _,
        tableDto: INotificationTableData<OrgNotificationModel> | INotificationTableData<NotificationScheduleDto>,
    ): React.ReactNode => {
        const { listType } = this.props

        const isOrgLevel = this.props.level === 'org'

        const isInternalNonReadOnlyUser = this.appState.currentUser?.isInternalUser === true && this.canMutate()

        const canMutate = this.canMutate()
        let canEditOrgNotif = canMutate
        if (this.props.level === 'org') {
            canEditOrgNotif = this.canEditOrgSegment(tableDto.data as OrgNotificationModel)
        }

        const data = tableDto.data
        const deliverySpec = extractDeliverySpecFromData(data)
        const abTest = extractAbTestFromData(data)

        let statusTypeKey: string
        if (data instanceof OrgNotificationModel) {
            statusTypeKey = data.getComputedStatus()?.toUpperCase?.() ?? StatusType.SCHEDULED.name
        } else {
            statusTypeKey = data.status.toUpperCase()
        }

        const computedStatus = StatusType[statusTypeKey]
        const isScheduledPush = computedStatus.id === StatusType.SCHEDULED.id
        const isSchedulingPush = computedStatus.id === StatusType.SCHEDULING.id
        const isDeliveringPush = computedStatus.id === StatusType.DELIVERING.id

        const isStzWindow = deliverySpec.window === NotificationDeliveryWindow.TIMEZONE
        const isDeliveringStzPush = isStzWindow && isDeliveringPush
        const isInfWindow = deliverySpec.window === NotificationDeliveryWindow.INFORMED
        const isDeliveringInfPush = isInfWindow && (isDeliveringPush || isSchedulingPush)

        const isCancellable = isDeliveringInfPush || isSchedulingPush || isScheduledPush || isDeliveringStzPush

        let actions: any = []

        if (canMutate) {
            if ((!isOrgLevel || canEditOrgNotif) && (isScheduledPush || isSchedulingPush || isDeliveringStzPush)) {
                actions.push({
                    text: 'Edit',
                    icon: 'edit',
                    onClick: this.handleNotificationEdit,
                    data,
                    altHref: this.buildEditUrl(data),
                })
            }

            actions.push({
                text: 'Duplicate',
                icon: 'copy',
                onClick: this.handleDuplicate,
                data,
                altHref: this.buildDuplicateUrl(data),
            })
        }

        if (abTest) {
            if (canMutate) {
                actions.push({ divider: true })
            }

            if (listType === NotificationListTypes.NOTIFICATION) {
                actions.push({
                    text: 'View Test',
                    icon: 'eye',
                    onClick: this.handleJumpToTestDetails,
                    data,
                    altHref: this.buildTestDetailsUrl(data),
                })
            }

            if (canMutate) {
                actions.push({
                    text: 'Duplicate Test',
                    icon: 'copy',
                    onClick: this.handleDuplicateTest,
                    data,
                    altHref: this.buildDuplicateUrl(data, true),
                })
            }
        }

        if (canMutate && isCancellable && (!isOrgLevel || canEditOrgNotif)) {
            actions.push({ divider: true })
            actions.push({
                text: !!abTest ? 'Cancel Test' : 'Cancel',
                icon: 'close',
                onClick: this.handleCancel,
                data,
            })
        }

        const canUserArchive =
            isInternalNonReadOnlyUser ||
            this.appState.abilityStore.can(AbilityAction.UPDATE, this.appState.abilityStore.currentDomainIdentity)

        if (this.state.level === 'domain' && canUserArchive) {
            if (actions.length) {
                actions.push({ divider: true })
            }

            actions.push({
                text: 'Archive',
                icon: 'folder',
                onClick: this.handleShowNotificationArchiveDialog,
                data,
                altHref: this.buildArchiveUrl(data),
            })
        }

        if (isInternalNonReadOnlyUser && listType === NotificationListTypes.NOTIFICATION) {
            if (actions.length) {
                actions.push({ divider: true })
            }

            actions.push({
                text: 'Set Note',
                icon: 'edit',
                onClick: () => this.toggleAddtDetailsDrawer(data),
                data,
            })
        }

        if (actions.length === 0 || this.props.hideRowActions === true) {
            actions = undefined
        }

        return (
            <AsyncButton
                actions={actions}
                onClick={() => this.handlePrimaryActionClick(data, listType)}
                size="small"
                altHref={this.buildDetailsUrl(data)}
            >
                {listType === NotificationListTypes.NOTIFICATION ? <span>View</span> : <span>Edit</span>}
            </AsyncButton>
        )
    }

    protected getAbilityEntity() {
        return this.props.level === 'org' ? SubjectEntity.ORG_NOTIFICATION : SubjectEntity.NOTIFICATION
    }

    protected getAbilityConstraint() {
        return this.props.level === 'org' ? { accountId: this.props.orgId } : { domainId: this.props.domainId }
    }

    protected canMutate() {
        return this.appState.abilityStore.can(
            AbilityAction.UPDATE,
            asCaslSubject(this.getAbilityEntity(), this.getAbilityConstraint()),
        )
    }

    protected canEditOrgSegment(model: OrgNotificationModel) {
        const canEdit =
            this.props.level === 'domain'
                ? false
                : !model
                      .getNotifications()
                      .some(
                          (n) =>
                              !this.appState.abilityStore.can(
                                  AbilityAction.UPDATE,
                                  asCaslSubject(SubjectEntity.SEGMENT, { domainId: n.domainId }),
                              ),
                      )

        return canEdit
    }

    protected currentDomainHasSourceFiltering(state: State): boolean {
        let hasFlag = false

        if (state.level === 'domain' && state.domain) {
            const notifSourceFlag = this.appState.flags.findActive(FEAT_NOTIF_SOURCE_FILTERING)?.getKey()
            hasFlag = !!notifSourceFlag && state.domain.flags?.includes(notifSourceFlag)
        }

        return hasFlag
    }

    protected getDefaultSourceFilter(state: State): string[] {
        let source = DEFAULT_SOURCE_FILTER

        // callees include constructor so state is not guaranteed
        if (this.props.level === 'domain') {
            const domainSourceFilters = this.appSvc.getNotifListFilters('source', this.props.domainId)
            if (this.currentDomainHasSourceFiltering(state) && domainSourceFilters?.length) {
                source = domainSourceFilters
            }

            if (source.length === 0 && this.props.defaultFilters?.source) {
                source = this.props.defaultFilters.source
            }
        }

        return source
    }

    protected getDefaultStatusFilter(): string[] {
        let status = DEFAULT_STATUS_FILTER

        if (this.props.level === 'domain') {
            const domainStatusFilters = this.appSvc.getNotifListFilters('status', this.props.domainId)
            if (domainStatusFilters?.length) {
                status = domainStatusFilters
            }

            if (status.length === 0 && this.props.defaultFilters?.status) {
                status = this.props.defaultFilters.status
            }
        }

        return status
    }

    protected getCurrentDrawerData = <T extends NotificationData | undefined>(): T => {
        const currDataSource: IterableTableData = this.state.dataSource

        const match = currDataSource.find(({ data }) => {
            return this.state.addtDetailsDrawerNotifId === extractPrimaryIdFromData(data)
        })

        return match?.data as T
    }

    protected buildTestDetailsUrl(data: OrgNotificationModel | NotificationScheduleDto): string {
        const abTestId = extractAbTestFromData(data)?.id

        const newRoute = `/notifications/test/${abTestId}/summary`
        return this.appSvc.routeWithin(this.props.level, newRoute, true)
    }

    protected buildDetailsUrl(data: OrgNotificationModel | NotificationScheduleDto): string {
        const newRoute = `/notifications/${extractPrimaryIdFromData(data)}/summary`
        return this.appSvc.routeWithin(this.props.level, newRoute, true)
    }

    protected buildDuplicateUrl(
        data: OrgNotificationModel | NotificationScheduleDto,
        withTest: boolean = false,
    ): string {
        const abTestId = extractAbTestFromData(data)?.id

        let newRoute = `/notifications/new?notification_id=${extractPrimaryIdFromData(data)}`
        if (withTest && abTestId) {
            newRoute = `${newRoute}&test=${abTestId}`
        }

        return this.appSvc.routeWithin(this.props.level, newRoute, true)
    }

    protected buildEditUrl(data: OrgNotificationModel | NotificationScheduleDto): string {
        let notifId: IdType
        let abTestId: IdType | undefined

        if (data instanceof OrgNotificationModel) {
            notifId = data.getId()!
        } else {
            notifId = data.notificationId
            abTestId = data.abTest?.id
        }

        let newRoute = `/notifications/edit/${notifId}`
        if (this.props.level === 'org') {
            newRoute = `/notifications/${notifId}/edit`
        }

        if (abTestId) {
            newRoute = `${newRoute}?test=${abTestId}`
        }

        return this.appSvc.routeWithin(this.props.level, newRoute, true)
    }

    protected buildArchiveUrl(data: OrgNotificationModel | NotificationScheduleDto): string {
        const newRoute = `/notifications/${extractPrimaryIdFromData(data)}`
        return this.appSvc.routeWithin(this.props.level, newRoute, true)
    }

    protected async setStateAsync<K extends keyof State>(
        state:
            | ((prevState: Readonly<State>, props: Readonly<NotificationListProps>) => Pick<State, K> | State | null)
            | (Pick<State, K> | State | null),
    ): Promise<void> {
        return new Promise((res) => {
            if (!this.unmounting) {
                this.setState(state as State, res)
            }
        })
    }

    protected applyDefaults(state: State): State {
        // default query
        const query = getQueryStringParam('query')
        if (!!query?.trim()) {
            state.filters = {
                ...state.filters,
                search: query.trim(),
            }
        }

        // default refreshTimer
        const refresh = getQueryStringParam(REFRESH_QS_PARAM, tryParseInt)
        if (refresh !== undefined && !isNaN(refresh)) {
            state.refreshTimeout = refresh * 1000
            if (refresh === 0) {
                state.refreshEnabled = false
            }
        }

        // ensure default page
        const currentPage = getQueryStringParam('page', tryParseInt)
        if (currentPage && currentPage !== 1) {
            state.paginationConfig = {
                ...state.paginationConfig,
                current: currentPage,
            }
        }

        if (this.props.level === 'domain') {
            state = this.applyDefaultFilters(state)
        }

        return state
    }

    protected applyDefaultFilters(state: State) {
        // ensure default source filter from user state
        state.filters = {
            ...(state.filters ?? this.state?.filters ?? {}),
            source: this.props.defaultFilters?.source ?? this.getDefaultSourceFilter(state),
            status: this.props.defaultFilters?.status ?? this.getDefaultStatusFilter(),
        }

        return state
    }

    protected updatePathParams(): void {
        const {
            paginationConfig: { current: page },
        } = { ...this.state }

        let parsedUpdate: string | undefined

        if (page !== 1) {
            parsedUpdate = `&page=${page}`
        }

        const refresh = getQueryStringParam(REFRESH_QS_PARAM)
        if (refresh !== undefined) {
            parsedUpdate = `${parsedUpdate ?? ''}&${REFRESH_QS_PARAM}=${refresh}`
        }

        const refreshOpts = getQueryStringParam(REFRESH_OPTS_QS_PARAM)
        if (refreshOpts !== undefined) {
            parsedUpdate = `${parsedUpdate ?? ''}&${REFRESH_OPTS_QS_PARAM}=${refreshOpts}`
        }

        this.props.history?.push?.({ search: parsedUpdate?.replace(/^&/, '') })
    }

    protected async resetList() {
        await this.setStateAsync({ dataSourceLoaded: false })

        await this.setStateAsync((curr) => ({
            previousRowIds: [],
            paginationConfig: {
                ...curr.paginationConfig,
                current: 1,
            },
            filters: {
                ...curr.filters,
                source: this.getDefaultSourceFilter(curr),
                status: this.getDefaultStatusFilter(),
                search: undefined,
            },
        }))

        this.fetchNotifications({ forceFetch: true, listType: this.props.listType })
    }

    protected async setCurrentPage(page: number | undefined): Promise<void> {
        await this.setStateAsync(({ paginationConfig }) => ({
            paginationConfig: {
                ...paginationConfig,
                current: page ?? 1,
            },
        }))

        this.updatePathParams()
    }

    protected handleTableChange = async (
        pagination: TablePaginationConfig,
        _filters: Record<string, Key[] | null>,
        _sorter: SorterResult<TableData> | SorterResult<TableData>[],
        _extra: TableCurrentDataSource<TableData>,
    ): Promise<void> => {
        await this.setCurrentPage(pagination.current)
        this.fetchNotifications({ listType: this.props.listType })
    }

    protected handleSearchClick = async (value: string) => {
        const prevFilter = this.state.filters.search

        await this.setStateAsync(({ filters }) => ({
            filters: {
                ...filters,
                search: value,
            },
        }))

        const emptyValues = !value.trim() && !prevFilter?.trim()
        if (!emptyValues) {
            await this.setCurrentPage(1)
            this.fetchNotifications({
                forceFetch: true,
                listType: this.props.listType,
            })
        }
    }

    protected handleFilterChange = async <FilterValue extends NotificationListState['filters'][FilterKey]>(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void> => {
        const prevFilters = clone(this.state.filters)

        if (Array.isArray(value) && (key === 'status' || key === 'source')) {
            value.sort()
        }

        if (this.state.level === 'domain' && key === 'source') {
            this.appSvc.setNotifListFilters('source', this.state.domain.id, value as string[])
        }

        if (this.state.level === 'domain' && key === 'status') {
            this.appSvc.setNotifListFilters('status', this.state.domain.id, value as string[])
        }

        await this.setStateAsync(({ filters }) => ({
            filters: {
                ...filters,
                [key]: value,
            },
        }))

        if (!deepEqual(this.state.filters, prevFilters)) {
            // ensure table load animation shows after filter change
            await this.setStateAsync({ refreshing: false })

            await this.setCurrentPage(1)
            this.fetchNotifications({ listType: this.props.listType })
        }
    }

    protected handleRefreshClick = async (_ev: React.MouseEvent<HTMLElement>): Promise<void> => {
        // manual refresh click should not set refreshing
        // to ensure full table loading animation is shown
        await this.fetchNotifications({
            forceFetch: true,
            listType: this.props.listType,
        })

        this.updateAutoRefreshLastRunTimestamp?.()
    }

    protected handlePrimaryActionClick = (
        data: NotificationData,
        listType: NotificationListTypes = NotificationListTypes.NOTIFICATION,
    ): void => {
        if (listType === NotificationListTypes.NOTIFICATION) {
            this.appSvc.route(this.buildDetailsUrl(data))
        } else {
            this.appSvc.route(this.buildEditUrl(data))
        }
    }

    protected handleDuplicate = (item: any) => {
        this.appSvc.route(this.buildDuplicateUrl(item.props.data))
    }

    protected handleDuplicateTest = (item: any) => {
        this.appSvc.route(this.buildDuplicateUrl(item.props.data, true))
    }

    protected handleJumpToTestDetails = (item: any, ev: any): void => {
        if (!ev || !ev.metaKey) {
            preventAll(ev)
            this.appSvc.routeWithinDomain(this.buildTestDetailsUrl(item.props.data))
        }
    }

    protected handleNotificationEdit = (item: any) => {
        this.appSvc.route(this.buildEditUrl(item.props.data))
    }

    protected handleCancel = async (item: any): Promise<void> => {
        const { data } = item.props

        const refreshOptions = {
            showLoading: true,
            forceFetch: true,
            listType: this.props.listType,
        }

        if (this.state.level === 'domain') {
            if (data.abTest?.id) {
                await this.notificationSvc.cancelTest(this.state.domain.id, data.abTest.id)
            } else {
                await this.scheduleSvc.cancelSchedule(data)
            }
        } else {
            await this.orgNotificationSvc.cancel(data, {
                showLoadingScreen: true,
            })
        }

        this.fetchNotifications(refreshOptions)
    }

    protected handleDismissNotificationArchiveDialog = () => {
        return this.setState({
            showNotificationArchiveDialog: false,
            selectedNotification: null,
        })
    }

    protected handleShowNotificationArchiveDialog = (item: any) => {
        return this.setState({
            showNotificationArchiveDialog: true,
            selectedNotification: item.props.data,
        })
    }

    protected handleNotificationArchive = async (): Promise<void> => {
        this.handleDismissNotificationArchiveDialog()
        if (this.state.selectedNotification) {
            const notificationId = extractPrimaryIdFromData(this.state.selectedNotification)

            const refreshOptions = {
                showLoading: true,
                forceFetch: true,
                listType: this.props.listType,
            }

            if (this.state.level === 'domain') {
                await this.notificationSvc.archiveNotification(this.state.domain.id, notificationId)
            }
            this.fetchNotifications(refreshOptions)
        }
    }

    protected toggleAddtDetailsDrawer = (data: NotificationData) => {
        return this.setStateAsync({
            showAddtDetailsDrawer: true,
            addtDetailsDrawerNotifId: extractPrimaryIdFromData(data),
        })
    }

    protected handleAddtDetailsDrawerClose = () => {
        return this.setStateAsync({
            showAddtDetailsDrawer: false,
            addtDetailsDrawerNotifId: undefined,
        })
    }

    protected handleAutoRefresh = async () => {
        if (!this.unmounting && this.state.refreshEnabled) {
            // prevents full table loading animation
            await this.setStateAsync({ refreshing: true })
            await this.fetchNotifications({ forceFetch: true, listType: this.props.listType })
        }
    }

    protected async fetchNotifications(opts?: { listType?: NotificationListTypes; forceFetch?: boolean }) {
        const { filters } = this.state

        const isDraftList = opts?.listType === NotificationListTypes.DRAFT
        const defaultAllStatuses = ['CANCELLED', 'COMPLETED', 'DELIVERING', 'FAILED', 'HOLD_OUT', 'SCHEDULED']
        const statusFilters = isDraftList
            ? [StatusType.DRAFT.name]
            : Array.from(filters.status?.length === 0 ? defaultAllStatuses : filters.status ?? [])

        if (arrayContains(statusFilters, 'COMPLETED')) {
            statusFilters.push('COMPLETED_WITH_FAILURES')
        }

        let sourceFilters = Array.from(filters.source ?? [])
        if (this.props.level === 'org' || isDraftList) {
            sourceFilters = []
        }

        const apiOptions = {
            showLoadingScreen: false,
            cancellationKey: 'notif-list.fetch',
            query: stripUndefined({
                page: this.state.paginationConfig.current,
                limit: this.state.paginationConfig.pageSize,
                include_segments: 1,
                search: filters.search ?? undefined,
                source: sourceFilters.length > 0 ? sourceFilters.join(',') : undefined,
                status: statusFilters.length > 0 ? statusFilters.join(',') : undefined,
                multi_domain_only: !filters.showAllNotifications,
                audience_types: [NotificationAudienceType.SEGMENTED].join(','),
            }),
            version: ApiVersion.V4,
        }

        // Abort fetch if manual refresh and query has not changed
        const sameQuery =
            !this.state.refreshing &&
            this.state.dataSourcePreviousQuery &&
            deepEqual(apiOptions.query, this.state.dataSourcePreviousQuery)
        if (!opts?.forceFetch && sameQuery) {
            return
        }

        const currDataSource: IterableTableData = this.state.dataSource

        // Start loading animation
        await this.setStateAsync({
            dataSourceLoaded: false,
            dataSourcePreviousQuery: apiOptions.query,
            previousRowIds: currDataSource?.map(({ data }) => {
                return (data instanceof OrgNotificationModel ? data.getId() : data.id) as number
            }),
        })

        let nextDataSource: TableData[] = []
        const state: any = {
            ...this.state,
            refreshing: false,
            dataSourceLoaded: true,
        }

        let resolver
        if (this.props.level === 'domain') {
            resolver = this.scheduleSvc.fetchAllByDomainId(this.props.domainId, apiOptions)
        } else {
            resolver = this.orgNotificationSvc.fetchAll(this.props.orgId, apiOptions).then((r) => {
                if (r.ok && r.data) {
                    r.data = r.data.map((n) => {
                        const model = OrgNotificationModel.build(n)
                        model.setVariant(parseNotificationVariantFromApiResponse(n))
                        return model
                    })
                }

                return r
            })
        }

        const res = await Promise.resolve(resolver)
        if (!res.cancelled) {
            if (res.ok && res.data) {
                res.data.forEach((datum) => {
                    nextDataSource.push({
                        data: datum,
                        stats: {},
                    })
                })

                if (nextDataSource.length > 0) {
                    nextDataSource = await this.fetchStats(nextDataSource)
                }

                state.paginationLinks = res.meta.links ?? state.paginationLinks
                state.dataSource = nextDataSource
            } else if (res.error) {
                console.warn(res.error)
            }

            await this.setStateAsync(state)
        }
    }

    protected async fetchStats(dataSource: TableData[]): Promise<TableData[]> {
        const dataSourceWithStats = clone(dataSource)

        let domainIds
        let notifIdFilterField
        let notifIds
        let extractPrimaryIdFromStats: (_: any) => string
        const breakdowns: string[] = []
        const notifFields: string[] = []

        if (this.props.level === 'domain') {
            domainIds = [this.props.domainId]

            extractPrimaryIdFromStats = (s) => s.notification.id
            notifIdFilterField = 'notification.id'
            notifIds = arrayUnique(
                dataSource.map((d: INotificationTableData<NotificationScheduleDto>) => {
                    return d.data.notificationId
                }),
            )

            breakdowns.push('notification')
            notifFields.push(notifIdFilterField)
        } else {
            domainIds = arrayUnqiue(
                flatMap(dataSource, (d: INotificationTableData<OrgNotificationModel>) => {
                    return d.data.getNotifications()
                }).map((n) => n.domainId),
            )

            extractPrimaryIdFromStats = (s) => s.notification_group.id
            notifIdFilterField = 'notification_group.id'
            notifIds = arrayUnqiue(
                dataSource.map((d: INotificationTableData<OrgNotificationModel>) => {
                    return d.data.getId()
                }),
            )

            breakdowns.push('notification_group')
            notifFields.push(notifIdFilterField)
        }

        const insightsPkg: any = {
            entity: 'notifications',
            date_preset: 'lifetime',
            date_increment: 'lifetime',
            breakdowns,
            fields: [
                ...notifFields,
                'deliveries',
                'impressions',
                'clicks',
                'ctr',
                'ctr_decimal',
                'delivery_rate',
                'delivery_rate_decimal',
            ],
            filters: [
                {
                    field: 'domain.id',
                    operator: 'in',
                    value: domainIds,
                },
                {
                    field: notifIdFilterField,
                    operator: 'in',
                    value: notifIds,
                },
            ],
        }

        if (this.props.level === 'domain') {
            insightsPkg.action_attribution = 'send_time'
        }

        const stats = await this.insightsSvc.fetch(insightsPkg, false, 'nl.stats', this.props.level === 'org')

        if (stats?.find) {
            dataSourceWithStats.forEach((datum) => {
                const stat = stats.find(
                    (s: any) => String(extractPrimaryIdFromStats(s)) === String(extractPrimaryIdFromData(datum.data)),
                )

                if (stat) {
                    datum.stats.deliveries = stat.deliveries
                    datum.stats.impressions = stat.impressions
                    datum.stats.clicks = stat.clicks
                    datum.stats.ctr = stat.ctr
                    datum.stats.ctr_decimal = stat.ctr_decimal
                    datum.stats.delivery_rate = stat.delivery_rate
                    datum.stats.delivery_rate_decimal = stat.delivery_rate_decimal
                }
            })
        }

        return dataSourceWithStats
    }

    protected async fetchOrganizationalDependencies() {
        let state: any = { levelDependenciesLoaded: false }
        await this.setStateAsync({ ...state })

        if (this.props.level === 'domain') {
            const { ok, data } = await this.domainSvc.fetchById(this.props.domainId)
            if (ok && data) {
                state.domain = data
            }

            state.level = 'domain' // required for flag check
            state = this.applyDefaultFilters(state)
        } else {
            state.org = await this.orgSvc.fetchById(this.props.orgId)
        }

        state.levelDependenciesLoaded = true
        await this.setStateAsync(state)
    }
}

export default NotificationList
