import * as React from 'react'
import { DataViewContext } from '@pushly/aqe/lib/components/data-view/context'
import { DataView } from '@pushly/aqe/lib/components/data-view/data-view'
import { Table } from 'antd'
import { FeedService } from '../../services/feed.service'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { WifiOutlined } from '@ant-design/icons'
import { FeedNotification } from '@pushly/models/lib/structs/feeds/feed-notification'
import * as numeral from 'numeral'
import moment from 'moment-timezone'
import { useService } from '@pushly/aqe/lib/hooks'
import { useDataViewReducer } from '@pushly/aqe/lib/components/data-view/reducer'
import { DataSourceResponse, DataViewProps } from '@pushly/aqe/lib/components/data-view/types'
import { InsightsService } from '../../services/insights'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { FeedResponse } from '@pushly/models/lib/structs/feeds/feed-response'
import { AppService } from '../../services/index'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { AsyncButton } from '../async-button/async-button.component'

interface IFCWProps {
    domainId: number
    feed: LoadableDataState<FeedResponse>
}

interface ILoadableDataState<T> {
    data?: {
        feed: T
        meta: any
    }
    loading: boolean
}
export type LoadableDataState<T> = ILoadableDataState<T>

export type FeedNotificationWithStats = FeedNotification & { notification: any }

export const FeedDetailsWell = (props: IFCWProps) => {
    const feedSvc = useService(FeedService)
    const insightsSvc = useService(InsightsService)
    const appSvc = useService(AppService)

    const [context, dispatch, requestDataSourceReload] = useDataViewReducer<FeedNotification[]>({
        id: `feed-notifications`,
    })

    // ---------------------------------- Stateful Variables ---------------------------------- //
    const [pagination, setPagination] = React.useState<TablePaginationConfig>({
        pageSize: 20,
        total: 20,
        current: 1,
        showTotal: (total: number, range: number[]) => `Showing ${range[0]}-${range[1]} of ${total} Notifications`,
        size: 'small',
        showSizeChanger: false,
        onChange: async (currPage: number) => {
            await setPagination((config) => ({ ...config, current: currPage }))
        },
        itemRender: (current: any, type: 'prev' | 'next' | 'page', originalElement: any) => {
            const elementMap = {
                prev: <a>Previous</a>,
                next: <a>Next</a>,
                page: '',
            }

            return elementMap[type] ? elementMap[type] : originalElement
        },
    })

    const { feed, domainId } = props

    const feedData = feed.data?.feed

    const formatStat = (stat: number) => {
        if (stat % 1 > 0 || stat > 999) {
            return numeral(stat).format('0,0.00a')
        } else {
            return stat
        }
    }

    // ---------------------------------- Service Calls ---------------------------------- //
    const fetchStats = async (records: FeedNotification[]): Promise<FeedNotificationWithStats[]> => {
        const statPromises: Array<Promise<any>> = []
        const buildContract = (notificationId: string) => ({
            entity: 'notifications',
            date_preset: 'lifetime',
            date_increment: 'lifetime',
            action_attribute: 'send_time',
            breakdowns: ['notification'],
            fields: [
                'notification.id',
                'notification.title',
                'notification.landing_url',
                'notification.scheduled_time',
                'notification.scheduled_time_zone',
                'impressions',
                'clicks',
                'ctr_decimal',
            ],
            filters: [
                { field: 'domain.id', operator: 'eq', value: domainId },
                { field: 'notification.id', operator: 'eq', value: notificationId },
            ],
        })

        records.forEach((article: FeedNotificationWithStats) => {
            if (article.notificationId) {
                const contract = buildContract(article.notificationId.toString())

                const promise = insightsSvc
                    .fetch(contract, undefined, undefined, undefined, `/domains/${domainId}/notifications/insights`)
                    .then((res) => {
                        article.notification = res[0]
                    })

                statPromises.push(promise)
            }
        })

        await Promise.all(statPromises)

        return records.map((record: FeedNotificationWithStats) => record)
    }

    const getNotifs: DataViewProps<FeedNotification>['dataSourceLoader'] = async () => {
        const opts = {
            query: {
                page: pagination.current,
                limit: pagination.pageSize,
            },
        }

        let res = await feedSvc.fetchFeedNotifications(domainId, feedData!.id, opts)

        const stats = await fetchStats(res.data!)

        let dataViewResponse: DataSourceResponse = {
            meta: {},
            cancelled: false,
            ...res,
            data: [],
        }

        if (!res.cancelled) {
            if (res.ok && res.data) {
                dataViewResponse.data = stats.filter((f) => f.notification)
            } else if (res.error) {
                console.warn(res.error)
            }
        }

        return dataViewResponse
    }

    return (
        <div className="feed-notifications-table">
            <DataViewContext.Provider value={{ state: context, dispatch }}>
                <DataView
                    hideRefresh={true}
                    hideAutoRefreshOptions={true}
                    dataSourceLoader={getNotifs}
                    title="Feed Notifications"
                    table={{
                        rowKey: (row) => row.id!,
                        noData: {
                            text: 'No Notifications',
                            icon: <WifiOutlined />,
                        },
                        rowClassName: 'data-view-row-collapsed',
                    }}
                >
                    <Table.Column
                        align="left"
                        className="details"
                        title="Notifications"
                        render={(data) => {
                            let timestamp
                            let timeDisplay
                            const notificationTitle = data.notification.notification.title

                            if (data.notification.notification.scheduled_time_zone === 'STZ') {
                                timestamp = moment.utc(data.notification.notification.scheduled_time)
                                timeDisplay = `${timestamp.format('MMMM Do YYYY [at] h:mm a')} STZ`
                            } else {
                                timestamp = moment.tz(
                                    data.notification.notification.scheduled_time,
                                    data.notification.notification.scheduled_time_zone,
                                )
                                timeDisplay = timestamp.format('MMMM Do YYYY [at] h:mm a z')
                            }

                            return <TableRowEntityDisplay title={notificationTitle} description={timeDisplay} />
                        }}
                    />
                    <Table.Column
                        align="center"
                        className="imps"
                        dataIndex={['notification', 'impressions']}
                        render={(imp: number) => {
                            let impDisplay
                            if (!imp) {
                                impDisplay = '--'
                            } else {
                                impDisplay = formatStat(imp)
                            }

                            return <span>{impDisplay}</span>
                        }}
                        title="Impressions"
                    />
                    <Table.Column
                        align="center"
                        className="clicks"
                        dataIndex={['notification', 'clicks']}
                        render={(clicks: number) => {
                            let clicksDisplay
                            if (!clicks) {
                                clicksDisplay = '--'
                            } else {
                                clicksDisplay = formatStat(clicks)
                            }

                            return <span>{clicksDisplay}</span>
                        }}
                        title="Clicks"
                    />
                    <Table.Column
                        align="center"
                        className="ctr"
                        dataIndex={['notification', 'ctr_decimal']}
                        render={(ctr: number) => {
                            let ctrDisplay
                            if (!ctr) {
                                ctrDisplay = '--'
                            } else {
                                ctrDisplay = numeral(ctr).format('0.00%')
                            }

                            return <span>{ctrDisplay}</span>
                        }}
                        title="CTR"
                    />
                    <Table.Column
                        align="right"
                        className="actions"
                        render={(data) => (
                            <AsyncButton
                                size="small"
                                onClick={() =>
                                    appSvc.routeWithinDomain(`/notifications/${data.notificationId}/summary`)
                                }
                                altHref={appSvc.routeWithinDomain(
                                    `/notifications/${data.notificationId}/summary`,
                                    true,
                                )}
                            >
                                View
                            </AsyncButton>
                        )}
                        title="Actions"
                    />
                </DataView>
            </DataViewContext.Provider>
        </div>
    )
}
