import React from 'react'
import { SegmentBuilderContext } from './segment-builder-context'
import { Form } from 'antd'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../stores/app'
import { domainOptionsSort } from './helpers'
import { equalArrays } from '../../_utils/array'
import DomainAudienceSelect from '../domain-audience-select/domain-audience-select'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { DropdownViewSelectOption } from '../dropdown-view-with-select-all/dropdown-view-with-select-all'

type SegmentOrgDetailsBuilderProps = {
    onChange: (domainIds: number[], requiresValidation: boolean) => void
}

const SegmentOrgDetailsBuilder = (props: SegmentOrgDetailsBuilderProps) => {
    const context = React.useContext(SegmentBuilderContext)
    const appState = useService(AppState)

    const propsSelections: number[] = []

    let domainSelectorOptions: DropdownViewSelectOption<number>[] = []
    if (context.level === 'org' && appState.currentUserDomains) {
        domainSelectorOptions = appState.currentUserDomains
            .filter((d) => d.accountId === context.org.id)
            .filter((d) =>
                appState.abilityStore.can(
                    AbilityAction.CREATE,
                    asCaslSubject(SubjectEntity.SEGMENT, {
                        domainId: d.id,
                    }),
                ),
            )
            .map((d) => ({ value: d.id, label: d.displayName, title: d.displayName }))
        domainSelectorOptions.sort(domainOptionsSort)

        domainSelectorOptions.forEach((opt) => {
            if (context.selectedDomainIds.includes(opt.value)) {
                propsSelections.push(opt.value)
            }
        })
    }

    const [currSelections, setCurrSelections] = React.useState(propsSelections)
    const detailsDependenciesLoaded = context.levelDependenciesLoaded && context.segmentDependenciesLoaded
    const canRender = context.level === 'org' && detailsDependenciesLoaded

    if (context.level === 'org') {
        React.useEffect(() => {
            if (!equalArrays(context.selectedDomainIds, currSelections)) {
                setCurrSelections(context.selectedDomainIds)
            }
        }, [context.selectedDomainIds])
    }

    return !canRender ? null : (
        <Form.Item label="Domains" name="domains">
            <div>
                <DomainAudienceSelect
                    value={currSelections}
                    domainSelectorOptions={domainSelectorOptions}
                    onChange={(selections: number[]) => setCurrSelections(selections)}
                    onClear={() => props.onChange([], false)}
                    onDeselect={(selection: number) => {
                        const currValue = Array.from(currSelections)
                        currValue.splice(
                            currValue.findIndex((v) => v === selection),
                            1,
                        )
                        props.onChange(currValue, true)
                    }}
                    onDropdownVisibleChange={(open) => {
                        if (!open && !equalArrays(currSelections, propsSelections)) {
                            const requiresValidation = propsSelections.some((id) => !currSelections.includes(id))
                            props.onChange(currSelections, requiresValidation)
                        }
                    }}
                    handleDomainIdsChange={props.onChange}
                    domainIdChangeRequiresValidation={true}
                />
            </div>
        </Form.Item>
    )
}

export default SegmentOrgDetailsBuilder
