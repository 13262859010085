export enum NotificationBuilderTheme {
    STANDARD,
    MOBILE,
}

export enum NotificationBuilderLevel {
    ORG,
    DOMAIN,
    CAMPAIGN,
}

export enum NotificationBuilderSubmitType {
    STANDARD,
    PERSONAL_PREVIEW,
    TEAM_PREVIEW,
}

export enum IosInterruptionLevel {
    ACTIVE = 'active',
    TIME_SENSITIVE = 'time_sensitive',
    PASSIVE = 'passive',
    CRITICAL = 'critical',
}

export enum IosRelevanceScore {
    LOW = 0.1,
    NORMAL = 0.5,
    HIGH = 0.9,
}

export enum IosBadgeBehaviorAction {
    INCREMENT = 'increment',
    SET = 'set',
    DO_NOTHING = 'do_nothing',
}
