import React from 'react'
import classnames from 'classnames'
import { Select } from 'antd'
import {
    DropdownViewSelectOption,
    DropdownViewWithSelectAll,
    selectOptionMatchesFilter,
} from '../dropdown-view-with-select-all/dropdown-view-with-select-all'

export type DomainAudienceSelectProps = {
    disabled?: boolean
    value?: number[]
    domainSelectorOptions: DropdownViewSelectOption<number>[]
    onChange: (selections: number[]) => void
    onClear: () => void
    onDeselect?: (selection: number) => void
    onDropdownVisibleChange?: (visible: boolean) => void
    handleDomainIdsChange: (domainIds: number[], requiresValidation?: boolean) => void
    domainIdChangeRequiresValidation?: boolean
}

const DomainAudienceSelect: React.FC<DomainAudienceSelectProps> = (props) => {
    const [search, setSearch] = React.useState<string>('')
    const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false)

    const {
        value,
        disabled,
        domainSelectorOptions,
        handleDomainIdsChange,
        onChange,
        onClear,
        onDeselect,
        onDropdownVisibleChange,
        domainIdChangeRequiresValidation,
    } = props

    return (
        <Select<number[]>
            allowClear={true}
            onClear={onClear}
            className={classnames('org-notification-domain-audience-select', 'included-audience-select')}
            disabled={disabled}
            value={value}
            mode="multiple"
            placeholder="Select at least one domain to include"
            optionFilterProp="title"
            optionLabelProp="title"
            filterOption={selectOptionMatchesFilter}
            onSearch={setSearch}
            searchValue={search}
            options={domainSelectorOptions}
            dropdownClassName="org-notification-domain-audience-select-dropdown"
            open={dropdownOpen}
            onDeselect={(selection) => {
                if (onDeselect && !dropdownOpen) {
                    onDeselect(selection)
                }
            }}
            onDropdownVisibleChange={(open) => {
                setDropdownOpen(open)
                if (onDropdownVisibleChange) onDropdownVisibleChange(open)
            }}
            dropdownRender={(menu) => (
                <DropdownViewWithSelectAll
                    selectAllOptionClassName="domain-select-all-option"
                    menu={menu}
                    options={domainSelectorOptions}
                    selectedOptions={value ?? []}
                    filter={search}
                    onChange={(selections) => {
                        handleDomainIdsChange(selections, domainIdChangeRequiresValidation)
                        setSearch('')
                    }}
                />
            )}
            onChange={onChange}
        />
    )
}

export default DomainAudienceSelect
