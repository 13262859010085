import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps, IFilterBuilderState } from '../interfaces/filter-builder'
import * as deepEqual from 'react-fast-compare'
import { DomainService } from '../../../services'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { getClassNames } from '../../../_utils/classnames'
import clone from 'clone'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { DomainKeyword } from '../../../dtos/domain'
import classNames from 'classnames'

type NotificationKeywordsFilterBuilderValue = {
    field: 'NOTIFICATION_KEYWORDS'
    value: string[]
    operator: string
}

interface IState extends IFilterBuilderState {
    domainKeywords?: any[]
}

export class NotificationKeywordsFilterBuilder extends BetterComponent<IFilterBuilderProps, IState> {
    public state: IState = {
        operator: 'EQ',
        value: undefined,
    }

    private appState: AppState
    private domainService: DomainService

    public constructor(props: IFilterBuilderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)
    }

    public componentDidMount(): void {
        this.loadDomainKeywords()
    }

    public render(): React.ReactNode {
        const loading = !this.state.domainKeywords
        const domainKeywords: DomainKeyword[] = this.state.domainKeywords ?? []
        const keywordOptions = domainKeywords.map((kw) => ({ value: kw.name }))

        return (
            <div className={getClassNames('filter', 'nkw-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                            <span> </span>
                            <span className="filter-val">{this.currentValueDisplay}</span>
                        </span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'EQ', label: 'contains' },
                                    { value: 'NEQ', label: 'do not contain' },
                                ]}
                                defaultValue={['EQ']}
                                value={[this.currentValue.operator]}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                        <div className="filter-val">
                            <Select
                                size="small"
                                mode="multiple"
                                className="filter-val-dropdown"
                                dropdownClassName="filter-val-dropdown"
                                placeholder={
                                    !this.state.domainKeywords ? <Loading3QuartersOutlined spin={true} /> : 'Select...'
                                }
                                disabled={loading}
                                maxTagCount={2}
                                maxTagPlaceholder={(options) => {
                                    return `and ${options.length} more`
                                }}
                                options={keywordOptions}
                                value={this.currentValue.value}
                                onChange={this.handleValueChange}
                                tagRender={(props) => {
                                    const selectedValueIndex = this.currentValue.value.indexOf(String(props.value)) + 1
                                    const isLast = selectedValueIndex === this.currentValue.value.length

                                    return (
                                        <span
                                            className={classNames('filter-selection-item', {
                                                'filter-selection-item-last-item': isLast,
                                            })}
                                        >
                                            {props.label}
                                        </span>
                                    )
                                }}
                                onDropdownVisibleChange={(open) => {
                                    if (!open) {
                                        this.handleValueChangeComplete(this.currentValue.value)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): NotificationKeywordsFilterBuilderValue {
        const propsValue = this.props.value || {}

        return clone({
            field: 'NOTIFICATION_KEYWORDS',
            value: this.state.value || propsValue.value,
            operator: propsValue.operator || 'EQ',
        })
    }

    protected get currentOperatorDisplay(): string {
        const currentValue = this.currentValue.value || []

        return this.currentValue.operator === 'EQ'
            ? currentValue.length === 1
                ? 'contains'
                : 'contains one of'
            : currentValue.length === 1
            ? 'does not contain'
            : 'does not contain one of'
    }

    protected get currentValueDisplay(): string {
        let display: string = ''

        if (!this.currentValue.value) {
            display = 'any keyword'
        } else {
            const currentValue = clone(this.currentValue.value || [])
            if (currentValue.length <= 2) {
                display = currentValue.join(' or ')
            } else {
                const lastValue = currentValue.pop()
                display = currentValue.join(', ') + `, or ${lastValue}`
            }
        }

        return display
    }

    protected handleValueChange = async (value: string[]) => {
        if (Array.isArray(value) && value.length === 0) {
            value = []
        }

        await this.setState({ value })
        return value
    }

    protected handleValueChangeComplete = async (value: string[]) => {
        value = await this.handleValueChange(value)

        if (!deepEqual(value, (this.props.value || {}).value)) {
            this.handleChange({ value })
        }
    }

    protected handleChange(config: any): any {
        if (!!config) {
            this.props.onChange({
                field: 'NOTIFICATION_KEYWORDS',
                operator: this.currentValue.operator,
                ...this.props.value,
                ...config,
            })
        } else {
            this.props.onChange()
        }

        this.setState({ value: undefined })
    }

    protected async loadDomainKeywords(): Promise<void> {
        const keywords = await this.domainService.fetchKeywordsByDomainId(this.appState.currentDomain!.id, {
            query: { pagination: 0 },
            showLoadingScreen: false,
        })

        this.setState({ domainKeywords: keywords.data || [] })
    }
}
