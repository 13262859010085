import * as React from 'react'
import { PageHeader } from '@pushly/aqe/lib/components/index'
import { noop } from '@pushly/aqe/lib/utils/noop'
import { Breadcrumb, Tag } from 'antd'
import { LoadableDataState } from './feed-page'
import aqe from '@pushly/aqe'
import { secondsToHours, secondsToMinutes } from '@pushly/aqe/lib/utils/index'
import { FeedSendStrategy, RelativeDateDisplayMetric } from '@pushly/aqe/lib/enums/index'
import moment from 'moment-timezone'
import { useService } from '@pushly/aqe/lib/hooks/index'
import { AppService } from '../../services/index'
import { convertToStringDay } from '../../_utils/utils'
import { FeedResponse } from '@pushly/models/lib/structs/feeds/feed-response'
import { FixedFeedSchedule } from '@pushly/models/lib/structs/feeds/fixed-feed-schedule'

interface IFPHProps {
    domainName: string | undefined
    feed: LoadableDataState<FeedResponse>
}

const DOMAINS_ROUTE = '/domains'

export const FeedPageHeader = (props: IFPHProps) => {
    const appService = useService(AppService)
    const { data, loading } = props.feed

    const handleBreadcrumbClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        if (!ev.metaKey) {
            ev.preventDefault()
            appService.route(ev.currentTarget.getAttribute('href')!)
        }
    }

    function buildDetails(): React.ReactNode {
        const { domainId, url, enabled, config, segments, excludedSegments } = data!.feed

        return (
            <div className="detail-items">
                <div className="url">
                    <span>
                        <b>URL </b>
                        <a href={url} target="_blank" rel="noreferrer noopener">
                            <span>{url}</span>
                        </a>
                    </span>
                </div>
                <div className="feed-enabled">
                    <span>
                        <b>Status</b> {enabled ? 'Enabled' : 'Disabled'}
                    </span>
                </div>

                <div className="send-dates">
                    <span>
                        <b>Send Dates</b>&nbsp;
                        {moment(config?.schedule.limitedScheduleDates.startDate).format('ddd, LL')} through{' '}
                        {config?.schedule.limitedScheduleDates.endDate
                            ? moment(config?.schedule.limitedScheduleDates.endDate).format('ddd, LL')
                            : 'Ongoing'}
                    </span>
                </div>

                <div className="send-time">
                    <span>
                        <b>Send Time</b>&nbsp;
                        {config?.schedule.startTime
                            ? `${moment(config.schedule.startTime, 'HH:mm').format('h:mm A')} - ${convertToStringDay(
                                  config.schedule.dayOfWeekIso,
                              )} in ${
                                  config.schedule.sendStrategy !== FeedSendStrategy.SUBSCRIBER_TIME_ZONE
                                      ? "Domain's Time Zone"
                                      : "Subscriber's Time Zone"
                              }`
                            : 'New Article Posted'}
                    </span>
                </div>

                <div className="delivery-delay">
                    <span>
                        <b>Pre-Schedule</b>&nbsp;
                        {(config?.schedule as FixedFeedSchedule)?.deliveryDelay
                            ? (config.schedule as FixedFeedSchedule).deliveryDelay!.displayMetric ===
                              RelativeDateDisplayMetric.MINUTES
                                ? secondsToMinutes(
                                      (config.schedule as FixedFeedSchedule).deliveryDelay!.intervalSeconds,
                                  ) +
                                  ` ${(config.schedule as FixedFeedSchedule).deliveryDelay!.displayMetric}` +
                                  ' before send time'
                                : secondsToHours(
                                      (config.schedule as FixedFeedSchedule).deliveryDelay!.intervalSeconds,
                                  ) +
                                  ` ${(config.schedule as FixedFeedSchedule).deliveryDelay!.displayMetric}` +
                                  ' before send time'
                            : 'None'}
                    </span>
                </div>

                <div className="audience">
                    <b>Segments</b>
                    {segments &&
                        segments.map((s, idx) => (
                            <a
                                key={`i-${idx}`}
                                href={`${aqe.defaults.publicPlatformDomain}/domains/${domainId}/segments/${s.id}/summary`}
                                target="_blank"
                            >
                                <Tag key={`segment-${idx}`}>{s.name}</Tag>
                            </a>
                        ))}
                </div>

                <div className="audience">
                    <b>Excluded Segments</b>
                    {excludedSegments
                        ? excludedSegments.map((s, idx) => (
                              <a
                                  key={`i-${idx}`}
                                  href={`${aqe.defaults.publicPlatformDomain}/domains/${domainId}/segments/${s.id}/summary`}
                                  target="_blank"
                              >
                                  <Tag key={`segment-${idx}`}>{s.name}</Tag>
                              </a>
                          ))
                        : 'None'}
                </div>

                <div className="required-keyword">
                    <div>
                        <b>Required Keywords</b>&nbsp;
                        {config?.requiredKeywords?.length
                            ? config.requiredKeywords.map((kw, idx) => <Tag key={`required-kw-${idx}`}>{kw}</Tag>)
                            : 'None'}
                    </div>
                </div>

                <div className="restricted-keywords">
                    <div>
                        <b>Restricted Keywords</b>&nbsp;
                        {config?.restrictedKeywords
                            ? config.restrictedKeywords.map((kw, idx) => <Tag key={`restricted-kw-${idx}`}>{kw}</Tag>)
                            : 'None'}
                    </div>
                </div>

                <div className="url-allowlist">
                    <div>
                        <b>Allowlisted URLs</b>&nbsp;
                        {config?.urlAllowlist
                            ? config.urlAllowlist.map((url_, idx) => <Tag key={`allowlisted-url-${idx}`}>{url_}</Tag>)
                            : 'None'}
                    </div>
                </div>

                <div className="url-denylist">
                    <div>
                        <b>Denylisted URLs</b>&nbsp;
                        {config?.urlDenylist
                            ? config.urlDenylist.map((url_, idx) => <Tag key={`denylisted-url-${idx}`}>{url_}</Tag>)
                            : 'None'}
                    </div>
                </div>

                <div className="title-denylist">
                    <div>
                        <b>Denylisted Titles</b>&nbsp;
                        {config?.titleDenylist
                            ? config.titleDenylist.map((title, idx) => (
                                  <Tag key={`denylisted-title-${idx}`}>{title}</Tag>
                              ))
                            : 'None'}
                    </div>
                </div>
            </div>
        )
    }

    function render() {
        const feed = data!.feed

        return (
            <div className="feed-details">
                <div className="feed-title">
                    <PageHeader
                        append={
                            feed && (
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        href={`${DOMAINS_ROUTE}/${feed.domainId}`}
                                        onClick={handleBreadcrumbClick}
                                    >
                                        {props.domainName}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        href={`${DOMAINS_ROUTE}/${feed.domainId}/notifications/feeds`}
                                        onClick={handleBreadcrumbClick}
                                    >
                                        Feeds
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>ID: {feed.id}</Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        }
                        onTitleSet={noop}
                        loading={loading}
                        title={feed && (feed.name || 'Feed Configuration')}
                    />
                    {feed && <div className="append">{buildDetails()}</div>}
                </div>
            </div>
        )
    }

    return render()
}
