import * as React from 'react'
import { Button } from '@pushly/aqe/lib/components/index'
import './error-page.scss'
import { PlatformUserContext } from '../../providers/user-provider/user-provider'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services/index'
import { Observer } from 'mobx-react'

export type ErrorPageProps = {
    label?: React.ReactNode
    hideLogo?: boolean
    hideBackgroundCode?: boolean
    showAction?: boolean
    errorCode: 403 | 404 | 500 | 503
    subLabel?: React.ReactNode
    error?: any
    info?: any
}

const errorLabels = {
    403: 'Access Denied',
    404: "Hmm, we couldn't find that page",
    500: "Sorry, but it's appears something went wrong",
    503: "Sorry, we're currently down for scheduled maintenance",
}

const subLabels = {
    500: 'Our team has been notified and will follow up when the issue has been resolved.',
    503: "We'll be back up shortly.",
}

export const ErrorPage = (props: ErrorPageProps) => {
    const appSvc = useService(AppService)
    const userContext = React.useContext(PlatformUserContext)
    let { errorCode, hideLogo, hideBackgroundCode, label, showAction, subLabel, error, info } = props

    hideLogo = hideLogo ?? false
    hideBackgroundCode = hideBackgroundCode ?? (errorCode === 500 || errorCode === 503)
    label = label ?? errorLabels[errorCode]
    subLabel = subLabel ?? subLabels[errorCode]

    function renderObservableError(children: any) {
        return <Observer>{() => children}</Observer>
    }

    function render() {
        return (
            <div className="pufferfish-error">
                {!hideBackgroundCode && <div className="background-text">{errorCode}</div>}
                {!hideLogo && <div className="logo" />}
                {label}
                {subLabel && <div className="sub-header">{subLabel}</div>}
                {showAction && (
                    <div>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() =>
                                userContext.authenticated ? appSvc.route('/dashboard') : location.assign('/')
                            }
                        >
                            Return to Platform
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    return errorCode === 500 && error && info ? renderObservableError(render()) : render()
}
